import {
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import {
    getUserSettingsApi,
    updateLanguageSettingsApi,
    updateUserSettingsApi
} from '@/api/client';
import { audioManager } from '@/audio/AudioManager';
import emitter from '@/events/emitter';
import { UserAndLanguageSettings, UserSettings } from '@/types/settings';
import { DEFAULT_VOLUME } from '@/utils/constants';

interface SettingsContextType {
    loadSettings: (onLoadingFinished: () => void) => Promise<void>;
    settings: UserAndLanguageSettings;
    setUILanguage: (languageCode: string) => Promise<void>;
    setStudyLanguage: (languageCode: string) => Promise<void>;
    addNewStudyLanguage: (languageCode: string) => Promise<void>;
    setLanguageLevel: (studyLanguage: string, level: string) => Promise<void>;
    setSfxVolume: (volume: number) => Promise<void>;
    setIsVibrationOn: (isOn: boolean) => Promise<void>;
    updateDevControlsClickCounter: () => void;
    updateIsMagicWandTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateIsAudioHintTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateIsReviewModeTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateIsLeaderboardTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    setIsReviewWithoutSpacedRepViewed: () => Promise<void>;
    setIsSkipWordsIntro: (isSkip: boolean) => Promise<void>;
}

interface SettingsProviderProps {
    children: ReactNode;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
    undefined
);

export const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
    const [settings, setSettings] = useState<UserAndLanguageSettings>({});

    const [devControlsClickCounter, setDevControlsClickCounter] = useState(0);

    const loadSettings = useCallback(async (onLoadingFinished: () => void) => {
        try {
            const loadedSettings = await getUserSettingsApi();
            console.log('Loaded settings:', loadedSettings);
            setSettings(loadedSettings);
            audioManager.setVolume(loadedSettings.sfxVolume ?? DEFAULT_VOLUME);
            onLoadingFinished();
        } catch (error) {
            console.error('Failed to load settings', error);
            throw error;
        }
    }, []);

    // Update the settings via API and update state
    const updateUserSettings = useCallback(
        async (updatedFields: Partial<UserSettings>) => {
            try {
                const updatedSettings =
                    await updateUserSettingsApi(updatedFields);
                setSettings(prevSettings => ({
                    ...prevSettings,
                    ...updatedSettings
                }));
            } catch (error) {
                console.error('Failed to update user settings', error);
                throw error;
            }
        },
        []
    );

    // Memoize frequent update functions
    const setUILanguage = useCallback(
        async (languageCode: string) => {
            await updateUserSettings({ uiLanguage: languageCode });
        },
        [updateUserSettings]
    );

    const setStudyLanguage = useCallback(
        async (languageCode: string) => {
            await updateUserSettings({ studyLanguage: languageCode });
        },
        [updateUserSettings]
    );

    const addNewStudyLanguage = useCallback(
        async (languageCode: string) => {
            const updatedStudyLanguages = [
                ...(settings.studyLanguages || []),
                languageCode
            ];
            await updateUserSettings({ studyLanguages: updatedStudyLanguages });
        },
        [settings.studyLanguages, updateUserSettings]
    );

    const setLanguageLevel = useCallback(
        async (studyLanguage: string, level: string) => {
            await updateLanguageSettingsApi(studyLanguage, level);
        },
        []
    );

    const setSfxVolume = useCallback(
        async (volume: number) => {
            audioManager.setVolume(volume);
            await updateUserSettings({ sfxVolume: volume });
        },
        [updateUserSettings]
    );

    const setIsVibrationOn = useCallback(
        async (isOn: boolean) => {
            await updateUserSettings({ isVibrationOn: isOn });
        },
        [updateUserSettings]
    );

    const setIsSkipWordsIntro = useCallback(
        async (isSkip: boolean) => {
            await updateUserSettings({ isSkipWordsIntro: isSkip });
        },
        [updateUserSettings]
    );

    const updateDevControlsClickCounter = useCallback(() => {
        if (devControlsClickCounter === 4) {
            const newIsShowDevControls = !settings.isShowDevControls;
            updateUserSettings({ isShowDevControls: newIsShowDevControls });
            setDevControlsClickCounter(0);
            emitter.emit('isShowDevControlsChanged', {
                isShowDevControls: newIsShowDevControls
            });
        } else {
            setDevControlsClickCounter(prevCount => prevCount + 1);
        }
    }, [
        devControlsClickCounter,
        settings.isShowDevControls,
        updateUserSettings
    ]);

    const updateIsMagicWandTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateUserSettings({ isMagicWandTooltipNeeded: isNeeded });
        },
        [updateUserSettings]
    );

    const updateIsAudioHintTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateUserSettings({ isAudioHintTooltipNeeded: isNeeded });
        },
        [updateUserSettings]
    );

    const updateIsReviewModeTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateUserSettings({ isReviewModeTooltipNeeded: isNeeded });
        },
        [updateUserSettings]
    );

    const updateIsLeaderboardTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateUserSettings({ isLeaderboardTooltipNeeded: isNeeded });
        },
        [updateUserSettings]
    );

    const setIsReviewWithoutSpacedRepViewed = useCallback(async () => {
        await updateUserSettings({ isReviewWithoutSpacedRepViewed: true });
    }, [updateUserSettings]);

    const contextValue = useMemo(
        () => ({
            loadSettings,
            settings,
            setUILanguage,
            setStudyLanguage,
            addNewStudyLanguage,
            setLanguageLevel,
            setSfxVolume,
            setIsVibrationOn,
            updateDevControlsClickCounter,
            updateIsMagicWandTooltipNeeded,
            updateIsAudioHintTooltipNeeded,
            updateIsReviewModeTooltipNeeded,
            updateIsLeaderboardTooltipNeeded,
            setIsReviewWithoutSpacedRepViewed,
            setIsSkipWordsIntro
        }),
        [
            loadSettings,
            settings,
            setUILanguage,
            setStudyLanguage,
            addNewStudyLanguage,
            setLanguageLevel,
            setSfxVolume,
            setIsVibrationOn,
            updateDevControlsClickCounter,
            updateIsMagicWandTooltipNeeded,
            updateIsAudioHintTooltipNeeded,
            updateIsReviewModeTooltipNeeded,
            updateIsLeaderboardTooltipNeeded,
            setIsReviewWithoutSpacedRepViewed,
            setIsSkipWordsIntro
        ]
    );

    return (
        <SettingsContext.Provider value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = (): SettingsContextType => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within SettingsProvider');
    }
    return context;
};
