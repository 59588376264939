import { type FC } from 'react';
import classNames from 'classnames';

import RoundFlag from '@/components/RoundFlag';
import { useLabels } from '@/context';
import { useVibration } from '@/hooks/useVibration';
import { Language } from '@/utils/languages';

import AnimatedButton from './AnimatedButton';

interface LanguageButtonProps {
    language: Language;
    isSelected: boolean;
    onPress: (languageCode: string) => void;
    isDisabled?: boolean;
}

const LanguageButton: FC<LanguageButtonProps> = ({
    language,
    isSelected,
    onPress,
    isDisabled = false
}) => {
    // TODO: pass label as prop instead of using context
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        if (isDisabled) return; // Do nothing if the button is disabled
        vibrateLight();
        onPress(language.languageCode);
    };

    // Determine button classes based on selected and disabled state
    const buttonClasses = classNames('flex items-center rounded-2xl', {
        'bg-white': !isSelected && !isDisabled,
        'bg-orange': isSelected && !isDisabled,
        'bg-grey400': isDisabled, // Grey background when disabled
        'opacity-50 cursor-not-allowed': isDisabled // Visual cues for disabled state
    });

    // Determine text color based on selected and disabled state
    const textColorClass = classNames({
        'text-grey500': !isSelected && !isDisabled,
        'text-white': isSelected && !isDisabled,
        'text-grey300': isDisabled // Lighter text when disabled
    });

    return (
        <AnimatedButton
            onPress={handlePress}
            isSelected={isSelected}
            isDisabled={isDisabled} // Pass isDisabled to AnimatedButton
            className={buttonClasses}
        >
            <div
                className="absolute rounded-full bg-grey100 p-0.5"
                style={{ left: '8px' }}
            >
                <RoundFlag isoCode={language.languageCode} size={25} />
            </div>

            <div className="w-full flex-1 text-center">
                <p
                    className={classNames(
                        'text-sm font-extrabold uppercase',
                        textColorClass
                    )}
                >
                    {getLabel(`language.${language.languageCode}`)}
                </p>
                <p
                    className={classNames(
                        'text-xs font-bold uppercase',
                        textColorClass
                    )}
                >
                    {language.languageNativeName.toUpperCase()}
                </p>
            </div>
        </AnimatedButton>
    );
};

export default LanguageButton;
