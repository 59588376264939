import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import { getLanguageByKey } from '@/utils/languages';

import prizesData from '../../assets/content/Prizes.json';
import {
    CategoriesData,
    CategoryInfoLocalized,
    WordFrequencyMap,
    WordInfoLocalized
} from '../types/category';
import { LandscapeInfoLocalized, LandscapesData } from '../types/prize';
import {
    processCategoriesData,
    processLandscapesData
} from '../utils/contentHandlers';

interface WordFrequencyData {
    words: string[];
}

interface ContentContextType {
    landscapes: LandscapeInfoLocalized[];
    getLandscape: (landscapeId: string) => LandscapeInfoLocalized | null;
    categories: CategoryInfoLocalized[];
    getWordsByCategory: (categoryId: string) => WordInfoLocalized[];
    wordFrequencyMap: WordFrequencyMap;
    loadContent: (
        uiLanguage: string,
        studyLanguage: string,
        onLoadingFinished: () => void
    ) => Promise<void>;
}

interface ContentProviderProps {
    children: ReactNode;
}

const ContentContext = createContext<ContentContextType | undefined>(undefined);

// Dynamic import functions for categories
type ImportFunction = () => Promise<{
    default: CategoriesData;
}>;

// Dynamic import functions for frequency lists
type WordFrequencyImportFunction = () => Promise<{
    default: WordFrequencyData;
}>;

// Use import.meta.glob to define all possible categories .json files
const categoriesImporters: Record<string, ImportFunction> = import.meta.glob<{
    default: CategoriesData;
}>('../../assets/content/categories/*.json');

// Use import.meta.glob to define all possible word frequency .txt files
const frequencyImporters: Record<string, WordFrequencyImportFunction> =
    import.meta.glob<{
        default: WordFrequencyData;
    }>('../../assets/content/frequency-lists/*.json');

// Caching for loaded categories json
const categoriesCache: Record<string, CategoriesData> = {};

// Caching for loaded frequency lists
const frequencyCache: Record<string, WordFrequencyMap> = {};

export const ContentProvider: FC<ContentProviderProps> = ({ children }) => {
    const [categories, setCategories] = useState<CategoryInfoLocalized[]>([]);
    const [landscapes, setLandscapes] = useState<LandscapeInfoLocalized[]>([]);
    const [wordFrequencyMap, setWordFrequencyMap] = useState<WordFrequencyMap>(
        new Map()
    );

    const loadContent = useCallback(
        async (
            uiLanguage: string,
            studyLanguage: string,
            onLoadingFinished: () => void
        ) => {
            console.log('Loading content...');

            if (!uiLanguage || !studyLanguage) {
                console.log('Language information is not available yet');
                onLoadingFinished();
                return;
            }

            // Validate the study language
            const selectedLanguage = getLanguageByKey(studyLanguage);
            if (!selectedLanguage) {
                console.error(`Unsupported study language: ${studyLanguage}`);
                onLoadingFinished();
                return;
            }

            // Validate the ui language
            const selectedUiLanguage = getLanguageByKey(uiLanguage);
            if (!selectedUiLanguage) {
                console.error(`Unsupported UI language: ${uiLanguage}`);
                onLoadingFinished();
                return;
            }

            try {
                // Load Categories
                let categoriesData: CategoriesData;

                if (categoriesCache[studyLanguage]) {
                    // Use cached data
                    categoriesData = categoriesCache[studyLanguage];
                    console.log(
                        `Loaded categories json for '${studyLanguage}' from cache.`
                    );
                } else {
                    // Construct the path based on studyLanguage
                    const importPath = `../../assets/content/categories/${studyLanguage}.json`;

                    const importer = categoriesImporters[importPath];

                    if (!importer) {
                        throw new Error(
                            `Categories json file not found for study language: ${studyLanguage}`
                        );
                    }

                    // Dynamically import the Categories json
                    const categoriesModule = await importer();
                    categoriesData = categoriesModule.default;

                    // Cache the loaded data
                    categoriesCache[studyLanguage] = categoriesData;
                    console.log(
                        `Loaded categories json for '${studyLanguage}' and cached.`
                    );
                }

                // Process and set categories with cleaned audioUrls
                const processedCategories = processCategoriesData(
                    categoriesData,
                    uiLanguage,
                    studyLanguage
                );
                setCategories(processedCategories);

                // Process Prizes.json as a single, static file
                const processedLandscapes = processLandscapesData(
                    prizesData as LandscapesData,
                    uiLanguage,
                    studyLanguage
                );
                setLandscapes(processedLandscapes);

                // Load frequency list
                let frequencyData: WordFrequencyMap;

                if (frequencyCache[studyLanguage]) {
                    // Use cached data
                    frequencyData = frequencyCache[studyLanguage];
                    console.log(
                        `Loaded frequency list for '${studyLanguage}' from cache.`
                    );
                } else {
                    // Construct the filename based on studyLanguage
                    const filename = `${studyLanguage}.json`;

                    // Find the matching importer
                    const frequencyImporterEntry = Object.entries(
                        frequencyImporters
                    ).find(([path]) => path.endsWith(`/${filename}`));

                    if (!frequencyImporterEntry) {
                        throw new Error(
                            `Frequency list not found for study language: ${studyLanguage}`
                        );
                    }

                    const [, frequencyImporter] = frequencyImporterEntry;

                    // Dynamically import the frequency list
                    const frequencyModule = await frequencyImporter();
                    const frequencyContent =
                        frequencyModule?.default?.words ?? [];

                    if (!frequencyContent || !Array.isArray(frequencyContent)) {
                        throw new Error(
                            `Frequency content is empty or invalid for study language: ${studyLanguage}`
                        );
                    }

                    console.log(
                        'Frequency content loaded:',
                        frequencyContent.length
                    );

                    // Build the WordFrequencyMap
                    frequencyData = new Map();

                    frequencyContent.forEach(word => {
                        if (typeof word !== 'string') return; // Ensure word is a string
                        const firstLetter = word.charAt(0).toLowerCase();
                        if (frequencyData.has(firstLetter)) {
                            frequencyData
                                .get(firstLetter)!
                                .push(word.toLocaleLowerCase());
                        } else {
                            frequencyData.set(firstLetter, [
                                word.toLocaleLowerCase()
                            ]);
                        }
                    });

                    // Cache the loaded data
                    frequencyCache[studyLanguage] = frequencyData;
                    console.log(
                        `Loaded frequency list for '${studyLanguage}' and cached.`
                    );
                }

                setWordFrequencyMap(frequencyData);

                console.log('Loading content finished');
            } catch (error) {
                console.error('Error loading content:', error);
            } finally {
                onLoadingFinished();
            }
        },
        []
    );

    const getLandscape = useCallback(
        (landscapeId: string): LandscapeInfoLocalized | null => {
            return (
                landscapes.find(landscape => landscape.id === landscapeId) ||
                null
            );
        },
        [landscapes]
    );

    const getWordsByCategory = useCallback(
        (categoryId: string): WordInfoLocalized[] => {
            const category = categories.find(cat => cat.id === categoryId);
            return category ? category.content : [];
        },
        [categories]
    );

    const contextValue = useMemo(
        () => ({
            loadContent,
            landscapes,
            categories,
            wordFrequencyMap,
            getLandscape,
            getWordsByCategory
        }),
        [
            loadContent,
            landscapes,
            categories,
            wordFrequencyMap,
            getLandscape,
            getWordsByCategory
        ]
    );

    return (
        <ContentContext.Provider value={contextValue}>
            {children}
        </ContentContext.Provider>
    );
};

export const useContent = (): ContentContextType => {
    const context = useContext(ContentContext);
    if (context === undefined) {
        throw new Error('useContent must be used within a ContentProvider');
    }
    return context;
};
