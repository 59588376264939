import {
    CategoryProgressItem,
    GameProgressValues,
    LandscapeProgressItem,
    WordProgressItem
} from '@/types/progress';
import { UserAndLanguageSettings, UserSettings } from '@/types/settings';
import { DEFAULT_VOLUME } from '@/utils/constants';

import { User } from './types';

const DEFAULT_LANGUAGE_CODE = 'en';
const INITIAL_COINS = 1000;

const USER_SETTINGS_KEY = 'userSettings';
const COINS_KEY = 'coins';
const XP_KEY = 'xp';
const LANDSCAPE_ID_KEY = 'selectedLandscapeId';
const GAME_LEVEL_KEY = 'gameLevel';
const LANGUAGE_LEVEL_KEY = 'languageLevel';
const LANDSCAPE_PROGRESS_KEY = 'landscapeProgress';
const LANDSCAPES_PROGRESS_KEY = 'landscapesProgress';
const WORDS_PROGRESS_KEY = 'wordsProgress';

const DEFAULT_USER_SETTINGS: UserSettings = {
    uiLanguage: DEFAULT_LANGUAGE_CODE,
    studyLanguages: [],
    sfxVolume: DEFAULT_VOLUME,
    isVibrationOn: true,
    isShowDevControls: false,
    isMagicWandTooltipNeeded: true,
    isAudioHintTooltipNeeded: true,
    isReviewModeTooltipNeeded: true,
    isLeaderboardTooltipNeeded: true,
    isReviewWithoutSpacedRepViewed: false,
    isSkipWordsIntro: false
};

const addUser = (token: string): void => {
    const existingTokens = JSON.parse(
        localStorage.getItem('userTokens') || '[]'
    );
    if (!existingTokens.includes(token)) {
        existingTokens.push(token);
        localStorage.setItem('userTokens', JSON.stringify(existingTokens));
    }
};

const loadUser = (): User => {
    const now = new Date().toISOString();
    return {
        createdAt: now,
        updatedAt: now,
        lastSeen: now,
        coins: loadCoins()
    };
};

const checkIfUserExists = (token: string): boolean => {
    const existingTokens = JSON.parse(
        localStorage.getItem('userTokens') || '[]'
    );
    return existingTokens.includes(token);
};

const loadLanguageLevel = (studyLanguage: string): string | null => {
    if (!studyLanguage) {
        return null;
    }

    const key = `${studyLanguage}-${LANGUAGE_LEVEL_KEY}`;
    return localStorage.getItem(key);
};

const saveLanguageLevel = (
    studyLanguage: string,
    languageLevel: string
): void => {
    if (!studyLanguage) {
        return;
    }

    const key = `${studyLanguage}-${LANGUAGE_LEVEL_KEY}`;
    localStorage.setItem(key, languageLevel);
};

const loadSettings = (): UserAndLanguageSettings => {
    const storedSettings = localStorage.getItem(USER_SETTINGS_KEY);
    const settings = storedSettings
        ? JSON.parse(storedSettings)
        : DEFAULT_USER_SETTINGS;
    const languageLevel = settings.studyLanguage
        ? loadLanguageLevel(settings.studyLanguage)
        : undefined;
    return { ...settings, languageLevel };
};

const saveUserSettings = (settings: UserSettings) => {
    localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(settings));
};

const loadCoins = (): number => {
    const coins = localStorage.getItem(COINS_KEY);
    return coins ? parseInt(coins, 10) : INITIAL_COINS;
};

const loadSelectedLandscapeId = (studyLanguage: string): string | null => {
    if (!studyLanguage) {
        return null;
    }

    const key = `${studyLanguage}-${LANDSCAPE_ID_KEY}`;
    const result = localStorage.getItem(key);
    return result || null;
};

const loadLandscapeProgress = (studyLanguage: string): number => {
    if (!studyLanguage) {
        return 0;
    }

    const key = `${studyLanguage}-${LANDSCAPE_PROGRESS_KEY}`;
    const result = localStorage.getItem(key);
    return result ? parseInt(result, 10) : 0;
};

const saveLandscapeProgress = (
    studyLanguage: string,
    landscapeProgress: number
): void => {
    if (!studyLanguage) {
        return;
    }

    const key = `${studyLanguage}-${LANDSCAPE_PROGRESS_KEY}`;
    localStorage.setItem(key, landscapeProgress.toString());
};

const loadGameLevel = (studyLanguage: string): number => {
    if (!studyLanguage) {
        return 1;
    }

    const key = `${studyLanguage}-${GAME_LEVEL_KEY}`;
    const result = localStorage.getItem(key);
    return result ? parseInt(result, 10) : 1;
};

const loadProgress = (lang: string): GameProgressValues => {
    const coins = loadCoins();

    const xp = loadXp(lang);
    const selectedLandscapeId = loadSelectedLandscapeId(lang);
    const gameLevel = loadGameLevel(lang);
    const landscapeProgress = loadLandscapeProgress(lang);

    return {
        coins,
        xp,
        gameLevel,
        selectedLandscapeId,
        landscapeProgress
    };
};

const saveCoins = (coins: number): void => {
    localStorage.setItem(COINS_KEY, coins.toString());
};

const saveXp = (lang: string, xp: number): void => {
    const key = `${lang}-${XP_KEY}`;
    localStorage.setItem(key, JSON.stringify(xp));
};

const loadXp = (lang: string): number => {
    const key = `${lang}-${XP_KEY}`;
    const result = localStorage.getItem(key);
    return result ? JSON.parse(result) : 0;
};

const saveGameLevel = (studyLanguage: string, level: number): void => {
    if (!studyLanguage) {
        return;
    }

    const key = `${studyLanguage}-${GAME_LEVEL_KEY}`;
    localStorage.setItem(key, level.toString());
};

const saveLandscapeId = (studyLanguage: string, landscapeId: string): void => {
    if (!studyLanguage) {
        return;
    }

    const key = `${studyLanguage}-${LANDSCAPE_ID_KEY}`;
    localStorage.setItem(key, landscapeId);
};

const getLanguageProgress = (lang: string): GameProgressValues => {
    const coins = loadCoins();
    const xp = loadXp(lang);
    const selectedLandscapeId = loadSelectedLandscapeId(lang);
    const gameLevel = loadGameLevel(lang);
    const landscapeProgress = loadLandscapeProgress(lang);

    return {
        coins,
        xp,
        gameLevel,
        selectedLandscapeId,
        landscapeProgress
    };
};

const loadWordsProgress = (studyLanguage: string): CategoryProgressItem[] => {
    if (!studyLanguage) return null;

    const key = `${studyLanguage}-${WORDS_PROGRESS_KEY}`;
    const result = localStorage.getItem(key);
    if (!result) return [];

    const data: CategoryProgressItem[] = JSON.parse(
        result
    ) as CategoryProgressItem[];

    return data.map(item => ({
        ...item,
        // Convert date strings back to Date objects for each category in the array
        unlockedAt: item.unlockedAt ? new Date(item.unlockedAt) : undefined,
        lastTimePlayed: item.lastTimePlayed
            ? new Date(item.lastTimePlayed)
            : undefined,
        wordProgressItems: item.wordProgressItems.map(wordItem => ({
            ...wordItem,
            firstTimePlayed: new Date(wordItem.firstTimePlayed),
            lastTimePlayed: new Date(wordItem.lastTimePlayed),
            movedToBoxAt: new Date(wordItem.movedToBoxAt)
        }))
    }));
};

const saveWordsProgress = (
    studyLanguage: string,
    categoryProgressItems: CategoryProgressItem[]
): void => {
    const key = `${studyLanguage}-${WORDS_PROGRESS_KEY}`;

    // Retrieve existing data or initialize as an empty array
    const existingData = localStorage.getItem(key);
    const categoryProgressArray: CategoryProgressItem[] = existingData
        ? JSON.parse(existingData)
        : [];

    // Loop through each item in the provided categoryProgressItems array
    categoryProgressItems.forEach(newProgressItem => {
        // Check if the category already exists
        const categoryIndex = categoryProgressArray.findIndex(
            item => item.categoryId === newProgressItem.categoryId
        );

        if (categoryIndex > -1) {
            // Existing category found
            const existingProgressItem = categoryProgressArray[categoryIndex];

            // Merge category-level properties using destructuring
            const mergedCategoryProgressItem: CategoryProgressItem = {
                ...existingProgressItem,
                ...newProgressItem,
                wordProgressItems: undefined // We'll handle wordProgressItems separately
            };

            // Create a map of existing word progress items by wordId
            const existingWordProgressMap = new Map<string, WordProgressItem>();
            existingProgressItem.wordProgressItems.forEach(wpi => {
                existingWordProgressMap.set(wpi.wordId, wpi);
            });

            // Update or add the new word progress items
            newProgressItem.wordProgressItems.forEach(newWpi => {
                existingWordProgressMap.set(newWpi.wordId, newWpi);
            });

            // Reconstruct the wordProgressItems array
            const mergedWordProgressItems = Array.from(
                existingWordProgressMap.values()
            );

            // Combine everything back into a CategoryProgressItem
            const finalCategoryProgressItem: CategoryProgressItem = {
                ...mergedCategoryProgressItem,
                wordProgressItems: mergedWordProgressItems
            };

            // Update the existing category progress in the array
            categoryProgressArray[categoryIndex] = finalCategoryProgressItem;
        } else {
            // Add new category progress
            categoryProgressArray.push(newProgressItem);
        }
    });

    // Save the updated array back to localStorage
    localStorage.setItem(key, JSON.stringify(categoryProgressArray));
};

const loadLandscapesProgress = (
    studyLanguage: string
): LandscapeProgressItem[] => {
    if (!studyLanguage) return [];

    const key = `${studyLanguage}-${LANDSCAPES_PROGRESS_KEY}`;
    const result = localStorage.getItem(key);
    return result ? (JSON.parse(result) as LandscapeProgressItem[]) : [];
};

const saveLandscapesProgress = (
    studyLanguage: string,
    landscapeProgressItem: LandscapeProgressItem
): void => {
    const key = `${studyLanguage}-${LANDSCAPES_PROGRESS_KEY}`;

    // Retrieve existing data or initialize as an empty array
    const existingData = localStorage.getItem(key);
    const landscapeProgressArray: LandscapeProgressItem[] = existingData
        ? JSON.parse(existingData)
        : [];

    // Check if the landscape already exists (actually, it should never exist before we add it)
    const landscapeIndex = landscapeProgressArray.findIndex(
        item => item.landscapeId === landscapeProgressItem.landscapeId
    );

    if (landscapeIndex > -1) {
        // Update existing landscape progress
        landscapeProgressArray[landscapeIndex] = landscapeProgressItem;
    } else {
        // Add new landscape progress
        landscapeProgressArray.push(landscapeProgressItem);
    }

    // Save the updated array back to localStorage
    localStorage.setItem(key, JSON.stringify(landscapeProgressArray));
};

const clearStorage = () => {
    try {
        localStorage.clear();
        console.log('localStorage has been cleared!');
    } catch (error) {
        console.error('Error clearing localStorage:', error);
    }
};

export {
    addUser,
    checkIfUserExists,
    clearStorage,
    getLanguageProgress,
    loadCoins,
    loadGameLevel,
    loadLandscapeProgress,
    loadLandscapesProgress,
    loadProgress,
    loadSelectedLandscapeId,
    loadSettings,
    loadUser,
    loadWordsProgress,
    saveCoins,
    saveGameLevel,
    saveLandscapeId,
    saveLandscapeProgress,
    saveLandscapesProgress,
    saveLanguageLevel,
    saveUserSettings,
    saveWordsProgress,
    saveXp
};
