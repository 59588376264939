import { type FC } from 'react';
import { IconContext } from 'react-icons';
import { MdEdit } from 'react-icons/md';
import classNames from 'classnames';

import AnimatedButton from '@/components/buttons/AnimatedButton';
import { useLabels, useSettings } from '@/context';
import { useVibration } from '@/hooks/useVibration';

type ChangeUILanguageButtonProps = {
    onPress: () => void;
    isDisabled?: boolean;
};

const ChangeUILanguageButton: FC<ChangeUILanguageButtonProps> = ({
    onPress,
    isDisabled = false
}) => {
    const { getLabel } = useLabels();
    const { settings } = useSettings();
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        if (isDisabled) return;
        vibrateLight();
        onPress();
    };

    // Determine button classes based on disabled state
    const buttonClasses = classNames(
        'mb-10 rounded-2xl px-5 py-2.5 flex items-center justify-center relative',
        {
            'bg-grey400 cursor-not-allowed opacity-50': isDisabled, // Styles when disabled
            'bg-white hover:bg-grey100': !isDisabled // Styles when enabled
        }
    );

    // Determine text color based on disabled state
    const textColorClass = classNames({
        'text-grey300': isDisabled, // Lighter text when disabled
        'text-grey500': !isDisabled // Default text color when enabled
    });

    return (
        <AnimatedButton
            onPress={handlePress}
            isDisabled={isDisabled}
            showOverlay={false}
            className={buttonClasses}
        >
            <div className="relative flex items-center pr-6">
                <span
                    className={classNames(
                        'font-nunito text-lg',
                        textColorClass
                    )}
                >
                    {getLabel('onboarding.courses-available-in')}
                </span>
                <span
                    className={classNames(
                        'ml-2 mr-1 pt-0.5 font-bold uppercase',
                        textColorClass
                    )}
                >
                    {getLabel(`language.${settings.uiLanguage}`)}
                </span>
                <IconContext.Provider
                    value={{
                        size: '20px',
                        className: classNames('absolute right-0', {
                            'text-grey300': isDisabled, // Icon color when disabled
                            'text-grey500': !isDisabled // Icon color when enabled
                        })
                    }}
                >
                    <MdEdit />
                </IconContext.Provider>
            </div>
        </AnimatedButton>
    );
};

export default ChangeUILanguageButton;
