import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { audioManager } from '@/audio/AudioManager';
import {
    ScreenName,
    useBackgroundImage,
    useNavigation,
    useSettings
} from '@/context';
import {
    ChooseAnotherCategoryScreen,
    ChooseCategoryScreen,
    HelloScreen,
    ProgressPanel,
    PuzzleScreen,
    ReviewPuzzleScreen,
    SplashScreen,
    TimeToPlayScreen,
    WordsIntroScreen
} from '@/screens';
import {
    OpenLandscape,
    ResultsScreen,
    ReviewResultsScreen
} from '@/screens/achievements';
import BackgroundScreen from '@/screens/BackgroundScreen';
import {
    ChooseLanguageLevelScreen,
    ChooseStudyLanguageScreen,
    ChooseUILanguageScreen,
    ContentLoadingScreen
} from '@/screens/onboarding';
import {
    AccountScreen,
    AddStudyLanguageScreen,
    CategoriesScreen,
    CategoryInfoScreen,
    ChangeUILanguageScreen,
    ChooseNewLanguageLevelScreen,
    LandscapeInfoScreen,
    LandscapesScreen,
    LanguageSettingsScreen
} from '@/screens/profile';
import LeaderboardScreen from '@/screens/profile/LeaderboardScreen';
import WordsLearnedScreen from '@/screens/profile/WordsLearnedScreen';
import { commonStyles } from '@/styles/commonStyles';

import bgGray from '../../assets/images/bg-gray.png';
import levelImage from '../../assets/images/landscapes/onboarding/level.webp';
import loadingCourseImage from '../../assets/images/landscapes/onboarding/loading-course.webp';
import whichLanguageImage from '../../assets/images/landscapes/onboarding/which-language.webp';

import 'react-toastify/dist/ReactToastify.css';

const screensWithProgressPanel: ScreenName[] = [
    'Hello',
    'ChooseCategory',
    'Puzzle',
    'TimeToPlay',
    'Results',
    'OpenLandscape',
    'ReviewPuzzle',
    'ReviewResults'
];

const AppNavigator = () => {
    const { settings } = useSettings();
    const { applyCurrentLandscape, setBackgroundImage } = useBackgroundImage();
    const { currentScreen, currentProfileScreen } = useNavigation();

    const [activeScreen, setActiveScreen] = useState(currentScreen);
    const [activeProfileScreen, setActiveProfileScreen] =
        useState(currentProfileScreen);

    const [isProgressPanelVisible, setIsProgressPanelVisible] = useState(false);
    const [progressPanelVisibilityChanges, setProgressPanelVisibilityChanges] =
        useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isProfileTransitioning, setIsProfileTransitioning] = useState(false);

    useEffect(() => {
        if (settings?.sfxVolume !== undefined) {
            audioManager.setVolume(settings.sfxVolume);
        }
    }, [settings?.sfxVolume]);

    useEffect(() => {
        // Start fade-out transition before setting the new screen
        if (currentScreen !== activeScreen) {
            setIsTransitioning(true);
        }
    }, [currentScreen]);

    useEffect(() => {
        // Check if there's no change in the profile screen
        if (currentProfileScreen === activeProfileScreen) {
            return;
        }

        // Handling the initial load or transition from null
        if (currentProfileScreen !== null && activeProfileScreen === null) {
            setActiveProfileScreen(currentProfileScreen);
            // Transitioning from a screen to null (fade out)
        } else if (
            currentProfileScreen === null &&
            activeProfileScreen !== null
        ) {
            setIsProfileTransitioning(true);
        }
        // Transitioning between two different screens (fade out then in)
        else {
            setIsProfileTransitioning(true);
        }
        // Note: If both are null, nothing happens.
    }, [currentProfileScreen, activeProfileScreen]);

    useEffect(() => {
        switch (currentScreen) {
            case 'ChooseUILanguage':
            case 'WordsIntro':
            case 'ChooseAnotherCategory':
                return setBackgroundImage(bgGray);
            case 'ChooseStudyLanguage':
                return setBackgroundImage(whichLanguageImage);
            case 'ChooseLanguageLevel':
                return setBackgroundImage(levelImage);
            case 'ContentLoading':
                return setBackgroundImage(loadingCourseImage);
            case 'Hello':
            case 'ChooseCategory':
            case 'TimeToPlay':
            case 'Puzzle':
            case 'OpenLandscape':
            case 'Results':
                return applyCurrentLandscape();
            default:
                break;
        }
    }, [currentScreen]);

    useEffect(() => {
        const progressPanelVisible =
            screensWithProgressPanel.includes(currentScreen);

        setProgressPanelVisibilityChanges(
            progressPanelVisible !== isProgressPanelVisible
        );
    }, [currentScreen]);

    const getScreenComponent = () => {
        switch (activeScreen) {
            case 'Splash':
                return <SplashScreen />;
            case 'ChooseStudyLanguage':
                return <ChooseStudyLanguageScreen />;
            case 'ChooseUILanguage':
                return <ChooseUILanguageScreen />;
            case 'ChooseLanguageLevel':
                return <ChooseLanguageLevelScreen />;
            case 'ContentLoading':
                return <ContentLoadingScreen />;
            case 'Hello':
                return <HelloScreen />;
            case 'ChooseCategory':
                return <ChooseCategoryScreen />;
            case 'ChooseAnotherCategory':
                return <ChooseAnotherCategoryScreen />;
            case 'WordsIntro':
                return <WordsIntroScreen />;
            case 'TimeToPlay':
                return <TimeToPlayScreen />;
            case 'Puzzle':
                return <PuzzleScreen />;
            case 'OpenLandscape':
                return <OpenLandscape />;
            case 'Results':
                return <ResultsScreen />;
            case 'ReviewPuzzle':
                return <ReviewPuzzleScreen />;
            case 'ReviewResults':
                return <ReviewResultsScreen />;
            default:
                return null;
        }
    };

    const getProfileScreenComponent = () => {
        switch (activeProfileScreen) {
            case 'Account':
                return <AccountScreen />;
            case 'Categories':
                return <CategoriesScreen />;
            case 'CategoryInfo':
                return <CategoryInfoScreen />;
            case 'Landscapes':
                return <LandscapesScreen />;
            case 'LandscapeInfo':
                return <LandscapeInfoScreen />;
            case 'LanguageSettings':
                return <LanguageSettingsScreen />;
            case 'ChangeUILanguage':
                return <ChangeUILanguageScreen />;
            case 'AddStudyLanguage':
                return <AddStudyLanguageScreen />;
            case 'ChooseNewLanguageLevel':
                return <ChooseNewLanguageLevelScreen />;
            case 'WordsLearned':
                return <WordsLearnedScreen />;
            case 'Leaderboard':
                return <LeaderboardScreen />;
            default:
                return null;
        }
    };

    const renderCurrentScreen = () => {
        const currentScreenComponent = getScreenComponent();

        return (
            <div className={commonStyles.fullScreen}>
                <AnimatePresence>
                    {isProgressPanelVisible &&
                        !(
                            isTransitioning && progressPanelVisibilityChanges
                        ) && (
                            <motion.div
                                key="progressPanel"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.25 }}
                            >
                                <ProgressPanel />
                            </motion.div>
                        )}
                </AnimatePresence>
                <AnimatePresence
                    onExitComplete={() => {
                        setIsProgressPanelVisible(
                            screensWithProgressPanel.includes(currentScreen)
                        );

                        // Set the new screen after the fade-out transition is complete
                        setActiveScreen(currentScreen);
                        setIsTransitioning(false);
                    }}
                >
                    {!isTransitioning && activeScreen && (
                        <motion.div
                            key={activeScreen}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}
                            className="flex flex-1 flex-col px-2 pb-6 pt-3"
                        >
                            {currentScreenComponent}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        );
    };

    const renderProfileScreen = () => {
        if (activeProfileScreen === null) return null;

        const profileScreenComponent = getProfileScreenComponent();

        return (
            <div className={classNames(commonStyles.fullScreen, 'bg-grey700')}>
                <AnimatePresence
                    onExitComplete={() => {
                        // Set the new screen after the fade-out transition is complete
                        setActiveProfileScreen(currentProfileScreen);
                        setIsProfileTransitioning(false);
                    }}
                >
                    {!isProfileTransitioning &&
                        currentProfileScreen &&
                        activeProfileScreen && (
                            <motion.div
                                key={activeProfileScreen}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.25 }}
                                className="flex flex-1 flex-col p-6"
                            >
                                <div className="h-[calc(100vh-48px)]">
                                    {profileScreenComponent}
                                </div>
                            </motion.div>
                        )}
                </AnimatePresence>
            </div>
        );
    };

    return (
        <>
            <BackgroundScreen />
            {renderCurrentScreen()}
            {renderProfileScreen()}
            <ToastContainer />
        </>
    );
};

export default AppNavigator;
