import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import ModalInfo from '@/components/ModalInfo';
import { useContent, useGameProgress, useLabels } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { WordInfoLocalized } from '@/types/category';
import { shuffle } from '@/utils/array';
import { toUpperCaseWord } from '@/utils/language';

import QuestionInstructions from '../questions/QuestionInstructions';
import { BACKGROUND_COLOR_FOUND } from '../questions/questionStyles';
import QuestionTranslation from '../questions/QuestionTranslation';

import { questionPanelStyles } from './questionsPanelStyles';

interface ChooseTranslationQuestionsPanelProps {
    words: WordInfoLocalized[];
    selectionColor: string;
    studyLanguage: string;
    wordsFound: number;
}

const ChooseTranslationQuestionsPanel: FC<
    ChooseTranslationQuestionsPanelProps
> = ({ words, selectionColor, studyLanguage, wordsFound }) => {
    const [mounted, setMounted] = useState(true);
    const { getWordsByCategory } = useContent();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { gameProgress } = useGameProgress();

    const [showInstructions, setShowInstructions] = useState(true);
    const [isShowInstructionsModal, setIsShowInstructionsModal] =
        useState(false);
    const [selectedWord, setSelectedWord] = useState('');
    const [chooseTranslationForWord, setChooseTranslationForWord] =
        useState(undefined);

    const renderedOptionsForWordRef = useRef(undefined);
    const chooseTranslationForWordRef = useRef(undefined);
    chooseTranslationForWordRef.current = chooseTranslationForWord;

    const scaleControls = useAnimation();
    const fadeControls = useAnimation();

    useEffect(() => {
        setMounted(true);

        return () => {
            setMounted(false);
        };
    }, []);

    useEffect(() => {
        if (!chooseTranslationForWord || !mounted) return;

        emitter.emit('playSound', { sound: SoundName.Card });
        scaleControls.start({ scale: [1, 1.12, 1] });

        return () => {
            // Stop any ongoing animations if the component unmounts
            fadeControls.stop();
            scaleControls.stop();
        };
    }, [chooseTranslationForWord, scaleControls, mounted]);

    const resetState = (word?: WordInfoLocalized) => {
        if (word && word.id !== chooseTranslationForWordRef.current?.id) return;

        setSelectedWord('');
        setChooseTranslationForWord(undefined);
        renderedOptionsForWordRef.current = undefined;
    };

    useEffect(() => {
        const handleWordSelectionChanged = ({ word }) => {
            setShowInstructions(false);
            setSelectedWord(word);

            if (word !== '') {
                setChooseTranslationForWord(prev => {
                    if (prev) return undefined;
                    return prev;
                });
            }
        };

        const handleValidWordSelected = ({ wordId }) => {
            vibrateLight();

            const word = words.find(w => w.id === wordId);

            fadeControls.start({ opacity: 1 });
            setChooseTranslationForWord(word);
        };

        const handleInvalidWordSelected = () => {
            resetState();
            fadeControls.start({
                opacity: 0,
                transition: { duration: 0.3 }
            });
        };

        const handleCorrectAnswerGiven = ({ word }) => {
            if (word.id !== chooseTranslationForWord?.id) return;

            resetState(word);
            fadeControls.start({
                opacity: 0,
                transition: { duration: 0.3 }
            });
        };

        const handleAudioHintPlayWord = ({ wordId }) => {
            const word = words.find(w => w.id === wordId);
            if (!word) return;

            emitter.emit('playWordAudio', { audioUrl: word.audioUrl });
        };

        const handleEmptySpaceTap = () => {
            resetState();
            fadeControls.start({
                opacity: 0,
                transition: { duration: 0.3 }
            });
        };

        emitter.on('validWordSelected', handleValidWordSelected);
        emitter.on('invalidWordSelected', handleInvalidWordSelected);
        emitter.on('wordSelectionChanged', handleWordSelectionChanged);
        emitter.on('correctAnswerGiven', handleCorrectAnswerGiven);
        emitter.on('audioHintPlayWord', handleAudioHintPlayWord);
        emitter.on('emptySpaceTap', handleEmptySpaceTap);

        return () => {
            emitter.off('validWordSelected', handleValidWordSelected);
            emitter.off('invalidWordSelected', handleInvalidWordSelected);
            emitter.off('wordSelectionChanged', handleWordSelectionChanged);
            emitter.off('correctAnswerGiven', handleCorrectAnswerGiven);
            emitter.off('audioHintPlayWord', handleAudioHintPlayWord);
        };
    }, [chooseTranslationForWord]);

    const translationOptions = useMemo(() => {
        if (!chooseTranslationForWord) return null;
        if (
            chooseTranslationForWord.id ===
            renderedOptionsForWordRef.current?.id
        ) {
            return null;
        }

        renderedOptionsForWordRef.current = chooseTranslationForWord;

        const wordsInCategory = getWordsByCategory(
            chooseTranslationForWord.categoryId
        );

        // Filter out words with the same translation as chooseTranslationForWord
        const filteredWords = wordsInCategory.filter(
            w =>
                w.id !== chooseTranslationForWord.id &&
                w.translation !== chooseTranslationForWord.translation
        );

        shuffle(filteredWords);

        // Find words with the same part of speech but different translation
        const wordsWithTheSamePartOfSpeech = filteredWords.filter(
            w => w.partOfSpeech === chooseTranslationForWord.partOfSpeech
        );

        // Select another option
        const anotherOption =
            wordsWithTheSamePartOfSpeech.length > 0
                ? wordsWithTheSamePartOfSpeech[0]
                : filteredWords[0];

        // Ensure anotherOption exists before proceeding
        if (!anotherOption) {
            console.warn('No suitable alternative translation options found.');
            return null;
        }

        const options = [chooseTranslationForWord, anotherOption];
        shuffle(options);

        return options.map(option => {
            const isCorrect = option.id === chooseTranslationForWord.id;
            const showCursorHint =
                isCorrect && gameProgress.gameLevel === 1 && wordsFound === 0;
            return (
                <div
                    key={option.id}
                    className="flex max-w-[50%] flex-1 items-center"
                >
                    <QuestionTranslation
                        key={option.id}
                        word={option}
                        correctWord={chooseTranslationForWord}
                        studyLanguage={studyLanguage}
                        showCursorHint={showCursorHint}
                    />
                </div>
            );
        });
    }, [chooseTranslationForWord, words, gameProgress.gameLevel, wordsFound]);

    const onSelectedWordPress = () => {
        if (!chooseTranslationForWord) return;
        setIsShowInstructionsModal(true);
    };

    return (
        <div className={classNames(questionPanelStyles.container, 'relative')}>
            {showInstructions && (
                <QuestionInstructions
                    text={getLabel('puzzle.find-words-and-choose-translation')}
                />
            )}
            <div className={questionPanelStyles.selectedWordContainer}>
                {(selectedWord || chooseTranslationForWord) && (
                    <div onClick={onSelectedWordPress}>
                        <motion.div
                            initial={{ scale: 1 }}
                            animate={scaleControls}
                            className={questionPanelStyles.selectedWordWrapper}
                            style={
                                chooseTranslationForWord
                                    ? {
                                          backgroundColor:
                                              BACKGROUND_COLOR_FOUND
                                      }
                                    : {}
                            }
                        >
                            <p
                                className={questionPanelStyles.selectedWord}
                                style={{
                                    ...(chooseTranslationForWord && {
                                        color: selectionColor
                                    })
                                }}
                            >
                                {selectedWord ||
                                    toUpperCaseWord(
                                        chooseTranslationForWord?.word
                                    )}
                            </p>
                        </motion.div>
                    </div>
                )}
            </div>
            <motion.div
                initial={{ opacity: 1 }}
                animate={fadeControls}
                className="flex min-h-14 items-start"
            >
                {chooseTranslationForWord && translationOptions}
            </motion.div>
            <ModalInfo
                isVisible={isShowInstructionsModal}
                onClose={() => setIsShowInstructionsModal(false)}
                description={getLabel(
                    'puzzle.find-words-and-choose-translation'
                )}
            />
        </div>
    );
};

export default ChooseTranslationQuestionsPanel;
