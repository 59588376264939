import { CategoryInfoWithProgress, WordInfoLocalized } from '../types/category';

import { getCleanWord } from './language';

function filterOutLockedStampCollectionCategories(
    categoriesWithProgress: CategoryInfoWithProgress[]
): CategoryInfoWithProgress[] {
    return categoriesWithProgress.filter(
        c =>
            !c.categoryInfo.stampCollectionBonus ||
            c.categoryProgressItem.unlocked
    );
}

function getCategoriesToChooseFrom(
    categoriesWithProgress: CategoryInfoWithProgress[],
    currentKnowledgeLevel: string
): CategoryInfoWithProgress[] {
    const categoriesToChooseFrom = filterOutLockedStampCollectionCategories(
        categoriesWithProgress
    );

    console.log(
        `GetCategoriesToChooseFrom: categoriesWithProgress=${categoriesToChooseFrom.length}, currentKnowledgeLevel=${currentKnowledgeLevel}`
    );
    return getCategories(
        categoriesToChooseFrom,
        currentKnowledgeLevel,
        new Set<string>()
    );
}

function getCategories(
    categoriesWithProgress: CategoryInfoWithProgress[],
    currentKnowledgeLevel: string,
    triedLevels: Set<string>,
    foundCategories: CategoryInfoWithProgress[] = []
): CategoryInfoWithProgress[] {
    console.log(
        `GetCategories: currentKnowledgeLevel=${currentKnowledgeLevel}, triedLevels=${Array.from(
            triedLevels
        ).join(', ')}, foundCategories=${foundCategories?.length}`
    );
    if (triedLevels.has(currentKnowledgeLevel)) {
        return foundCategories ?? [];
    }
    triedLevels.add(currentKnowledgeLevel);

    // Prioritize start friendly and unlocked categories
    categoriesWithProgress = categoriesWithProgress.sort((a, b) => {
        const aUnlocked =
            a.categoryInfo.startFriendly || a.categoryProgressItem.unlocked
                ? 1
                : 0;
        const bUnlocked =
            b.categoryInfo.startFriendly || b.categoryProgressItem.unlocked
                ? 1
                : 0;
        return bUnlocked - aUnlocked;
    });

    // Initialize foundCategories if it's the first call
    foundCategories = foundCategories ?? [];

    // Step 0: Find the last played in-progress category of any level, prioritizing unlocked categories
    const inProgressCategoriesByLastTimePlayed = categoriesWithProgress
        .filter(
            c =>
                c.categoryProgressItem.wordProgressItems.length > 0 &&
                c.categoryProgressItem.wordProgressItems.length <
                    c.categoryInfo.content.length
        )
        .sort((a, b) => {
            const aTime = a.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            const bTime = b.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            return bTime - aTime;
        });

    const lastPlayedCategory =
        inProgressCategoriesByLastTimePlayed.length > 0
            ? inProgressCategoriesByLastTimePlayed[0]
            : null;

    if (lastPlayedCategory && !foundCategories.includes(lastPlayedCategory)) {
        foundCategories.push(lastPlayedCategory);
    }

    // Steps 1 & 2: Find in-progress and new categories for the current level
    const inProgressCategories = categoriesWithProgress
        .filter(
            c =>
                c !== lastPlayedCategory &&
                c.categoryProgressItem.wordProgressItems.length > 0 &&
                c.categoryProgressItem.wordProgressItems.length <
                    c.categoryInfo.content.length &&
                c.categoryInfo.languageLevel.toLocaleLowerCase() ===
                    currentKnowledgeLevel.toLocaleLowerCase()
        )
        .sort((a, b) => {
            const aTime = a.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            const bTime = b.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            return bTime - aTime;
        })
        .slice(0, 2);

    const newCategories = findNewCategories(
        categoriesWithProgress,
        currentKnowledgeLevel
    );

    // Add selected categories to foundCategories
    addSelectedCategories(foundCategories, inProgressCategories, newCategories);

    // Step 4: Repeat for the next level if less than 2 categories found
    if (foundCategories.length < 2) {
        const nextLevel = getNextKnowledgeLevel(
            currentKnowledgeLevel,
            triedLevels
        );
        return getCategories(
            categoriesWithProgress,
            nextLevel,
            triedLevels,
            foundCategories
        );
    }

    return foundCategories;
}

function addSelectedCategories(
    foundCategories: CategoryInfoWithProgress[],
    inProgressCategories: CategoryInfoWithProgress[],
    newCategories: CategoryInfoWithProgress[]
): void {
    // Helper function to add unique categories
    const addUniqueCategory = (
        sourceCategories: CategoryInfoWithProgress[]
    ) => {
        for (const category of sourceCategories) {
            if (!foundCategories.includes(category)) {
                foundCategories.push(category);
                if (foundCategories.length === 2) return; // Exit if we have 2 categories
            }
        }
    };

    addUniqueCategory(inProgressCategories);
    if (foundCategories.length === 2) return;

    addUniqueCategory(newCategories);
}

function findNewCategories(
    categoriesWithProgress: CategoryInfoWithProgress[],
    currentKnowledgeLevel: string
): CategoryInfoWithProgress[] {
    const random = new Random();

    // Get all startFriendly or unlocked categories for the current knowledge level
    const unlockedCategories = categoriesWithProgress
        .filter(
            c =>
                c.categoryInfo.languageLevel.toLocaleLowerCase() ===
                    currentKnowledgeLevel.toLocaleLowerCase() &&
                (c.categoryInfo.startFriendly ||
                    c.categoryProgressItem.unlocked) &&
                c.categoryProgressItem.wordProgressItems.length === 0
        )
        .sort(() => random.next() - 0.5);
    console.log(
        `FindNewCategories: unlockedCategories=${unlockedCategories.length}`
    );

    // If there are 2 or more startFriendly or unlocked categories, return the first 2
    if (unlockedCategories.length >= 2) {
        return unlockedCategories.slice(0, 2);
    }

    // If less than 2 startFriendly/unlocked categories, add other new categories
    const otherNewCategories = categoriesWithProgress
        .filter(
            c =>
                c.categoryInfo.languageLevel.toLocaleLowerCase() ===
                    currentKnowledgeLevel.toLocaleLowerCase() &&
                !(
                    c.categoryInfo.startFriendly ||
                    c.categoryProgressItem.unlocked
                ) &&
                c.categoryProgressItem.wordProgressItems.length === 0
        )
        .sort(() => random.next() - 0.5);
    console.log(
        `FindNewCategories: otherNewCategories=${otherNewCategories.length}`
    );

    // Combine startFriendly/unlocked with other new categories, shuffle, and take up to 2
    return [...unlockedCategories, ...otherNewCategories].slice(0, 2);
}

function getNextKnowledgeLevel(
    currentLevel: string,
    triedLevels: Set<string>
): string {
    let nextLevel: string;

    switch (currentLevel.toLowerCase()) {
        case 'beginner':
            nextLevel = 'intermediate';
            break;
        case 'intermediate':
            nextLevel = 'advanced';
            break;
        case 'advanced':
            nextLevel = 'beginner';
            break;
        default:
            nextLevel = 'beginner';
            break;
    }

    if (triedLevels.has(nextLevel)) {
        nextLevel = nextLevel === 'advanced' ? 'beginner' : 'intermediate';
    }

    return nextLevel;
}

class Random {
    public next(): number {
        return Math.random();
    }
}

function getWordsToStudyForCurrentCategory(
    currentCategoryWithProgress?: CategoryInfoWithProgress
): WordInfoLocalized[] {
    if (!currentCategoryWithProgress) {
        return [];
    }

    const words: WordInfoLocalized[] = [];

    // Get word progress items for the current category
    const wordProgressItems =
        currentCategoryWithProgress.categoryProgressItem.wordProgressItems;

    // Sort the content (words) for the current category by position ascending
    const sortedContent = currentCategoryWithProgress.categoryInfo.content.sort(
        (a, b) => a.position - b.position
    );

    // Get the content (words) for the current category that haven't been studied yet
    for (const wordInfo of sortedContent) {
        if (!wordProgressItems.some(wpi => wpi.wordId === wordInfo.id)) {
            words.push(wordInfo);

            if (words.length === 3) {
                break;
            }
        }
    }

    // If less than 3 words, add random words from the category
    if (words.length < 3) {
        // Filter the category's words to exclude those already added
        const remainingWords = sortedContent.filter(
            ci => !words.some(c => c.id === ci.id)
        );

        // Randomly select words to make the count up to 3
        while (words.length < 3 && remainingWords.length > 0) {
            const randomIndex = Math.floor(
                Math.random() * remainingWords.length
            );
            words.push(remainingWords[randomIndex]);
            remainingWords.splice(randomIndex, 1); // Remove to avoid duplicates
        }
    }

    return words;
}

function getUniqueCleanWords(
    words: WordInfoLocalized[],
    studyLanguage: string
): WordInfoLocalized[] {
    const uniqueWordsToPlayMap = new Map<string, WordInfoLocalized>();

    words.forEach(word => {
        if (uniqueWordsToPlayMap.has(getCleanWord(word.word, studyLanguage))) {
            return;
        }

        uniqueWordsToPlayMap.set(getCleanWord(word.word, studyLanguage), word);
    });

    return Array.from(uniqueWordsToPlayMap.values());
}

export {
    filterOutLockedStampCollectionCategories,
    getCategoriesToChooseFrom,
    getUniqueCleanWords,
    getWordsToStudyForCurrentCategory
};
