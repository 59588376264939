import { type FC, useCallback, useEffect, useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { Checkbox, Field, Label } from '@headlessui/react';
import Slider from 'rc-slider';

import { clearUserDataApi } from '@/api/client';
import { SoundName } from '@/audio/AudioManager';
import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';
import { SUPPORT_EMAIL } from '@/utils/constants';

import Modal from '../../components/Modal';

import 'rc-slider/assets/index.css';

interface SettingsProps {
    isVisible: boolean;
    onClose: () => void;
}

const Settings: FC<SettingsProps> = ({ isVisible, onClose }) => {
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { navigate } = useNavigation();
    const { addCoins } = useGameProgress();
    const {
        settings,
        setSfxVolume,
        setIsVibrationOn,
        setIsSkipWordsIntro,
        updateDevControlsClickCounter
    } = useSettings();

    const [sfxVolumeTmp, setSfxVolumeTmp] = useState(settings.sfxVolume);

    // **Manage Event Listener for Dev Controls**
    useEffect(() => {
        const handleIsShowDevControlsChanged = ({ isShowDevControls }) => {
            emitter.emit('playSound', { sound: SoundName.Done });
            toast(
                isShowDevControls
                    ? 'Dev controls enabled'
                    : 'Dev controls disabled'
            );
        };

        emitter.on('isShowDevControlsChanged', handleIsShowDevControlsChanged);

        return () => {
            emitter.off(
                'isShowDevControlsChanged',
                handleIsShowDevControlsChanged
            );
        };
    }, []);

    // **useAsyncFn for Clearing Async Storage**
    const [clearStorageState, doClearStorage] = useAsyncFn(async () => {
        await clearUserDataApi();
        navigate('Splash');
    }, [navigate]);

    // **useEffect to Handle Errors and Success for Clearing Storage**
    useEffect(() => {
        if (clearStorageState.error) {
            console.error(
                'Error cleaning async storage:',
                clearStorageState.error
            );
            toast.error('Failed to clear storage. Please try again.');
        }
    }, [clearStorageState.error, clearStorageState.value]);

    // **useAsyncFn for Adding Coins**
    const [addCoinsState, doAddCoins] = useAsyncFn(async () => {
        await addCoins(500);
        toast.success('500 coins added!');
    }, [addCoins]);

    // **useEffect to Handle Errors and Success for Adding Coins**
    useEffect(() => {
        if (addCoinsState.error) {
            console.error('Error adding coins:', addCoinsState.error);
            toast.error('Failed to add coins. Please try again.');
        }
    }, [addCoinsState.error, addCoinsState.value]);

    // **useAsyncFn for Triggering Error (Sentry Test)**
    const [triggerErrorState, doTriggerError] = useAsyncFn(async () => {
        throw new Error('Sentry test error');
    }, []);

    // **useEffect to Handle Errors for Triggering Error**
    useEffect(() => {
        if (triggerErrorState.error) {
            console.error('Triggered Sentry error:', triggerErrorState.error);
            toast.error('Triggered Sentry test error.');
        }
    }, [triggerErrorState.error]);

    // **useAsyncFn for Setting Vibration**
    const [setVibrationState, doSetVibration] = useAsyncFn(
        async (isOn: boolean) => {
            if (isOn) {
                vibrateLight(true);
            }
            await setIsVibrationOn(isOn);
            toast.success(getLabel('settings.updated'));
        },
        [setIsVibrationOn, vibrateLight]
    );

    // **useEffect to Handle Errors and Success for Setting Vibration**
    useEffect(() => {
        if (setVibrationState.error) {
            console.error('Error setting vibration:', setVibrationState.error);
            toast.error(getLabel('error.failed-to-update-settings'));
        }
    }, [setVibrationState.error, setVibrationState.value]);

    // **useAsyncFn for Setting Skip Words Intro**
    const [setSkipIntroState, doSetSkipIntro] = useAsyncFn(
        async (isSkip: boolean) => {
            await setIsSkipWordsIntro(isSkip);
        },
        [setIsSkipWordsIntro]
    );

    // **useEffect to Handle Errors and Success for Setting Skip Words Intro**
    useEffect(() => {
        if (setSkipIntroState.error) {
            console.error(
                'Error setting skip words intro:',
                setSkipIntroState.error
            );
            toast.error(getLabel('error.failed-to-update-settings'));
        }
    }, [setSkipIntroState.error, setSkipIntroState.value]);

    // **Handle Sound Effects Volume Change (No API Call, Local State Only)**
    const handleSoundsEffectsChangeComplete = useCallback(
        async volume => {
            try {
                setSfxVolumeTmp(volume);
                emitter.emit('playSound', { sound: SoundName.Click2 });
                await setSfxVolume(volume);
                toast.success(getLabel('settings.updated'));
            } catch (error) {
                console.error('Error setting sound effects volume:', error);
                toast.error(getLabel('error.failed-to-update-settings'));
            }
        },
        [setSfxVolume]
    );

    const handleSoundsEffectChange = useCallback(volume => {
        setSfxVolumeTmp(volume);
    }, []);

    // **Handle Vibration Toggle**
    const handleSetIsVibrationOn = useCallback(
        (isOn: boolean) => {
            doSetVibration(isOn);
        },
        [doSetVibration]
    );

    // **Handle Skip Words Intro Toggle**
    const handleSetIsSkipWordsIntro = useCallback(
        async (isSkip: boolean) => {
            await doSetSkipIntro(isSkip);
        },
        [doSetSkipIntro]
    );

    // **Handle Dev Controls Click Counter**
    const onSettingsTextClick = () => {
        updateDevControlsClickCounter();
    };

    // **Handle Copying Email to Clipboard**
    const onCopyToEmailClipboard = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(SUPPORT_EMAIL);
            toast.success(getLabel('settings.email-copied'));
            emitter.emit('playSound', { sound: SoundName.Click2 });
        } catch (error) {
            console.error('Error copying email to clipboard:', error);
            toast.error(getLabel('error.failed-to-copy-email'));
        }
    }, [getLabel]);

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <div className={commonStyles.modalPanel}>
                <div className="flex justify-between">
                    <h1
                        onClick={onSettingsTextClick}
                        className="cursor-pointer"
                    >
                        {getLabel('settings.settings')}
                    </h1>
                    <button onClick={onClose} aria-label="Close">
                        <CloseIcon height={16} width={16} color="white" />
                    </button>
                </div>
                <div className="my-5 flex flex-col items-center justify-center">
                    {/* Sound Effects Volume Slider */}
                    <div className="mb-6 flex w-full flex-col">
                        <label className="mb-3 text-lg text-white200">
                            {getLabel('settings.sound-effects')}
                        </label>
                        <Slider
                            value={sfxVolumeTmp}
                            onChange={handleSoundsEffectChange}
                            onChangeComplete={handleSoundsEffectsChangeComplete}
                            min={0}
                            max={100}
                            step={1}
                        />
                    </div>

                    {/* Vibration Toggle */}
                    <Field className="flex w-full gap-2">
                        <Checkbox
                            checked={settings.isVibrationOn}
                            onChange={handleSetIsVibrationOn}
                            className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-inset ring-white/15 data-[checked]:bg-white"
                            disabled={setVibrationState.loading}
                        >
                            <FaCheck className="hidden size-4 fill-black group-data-[checked]:block" />
                        </Checkbox>
                        <Label className="text-grey300">
                            {getLabel('settings.vibration')}
                        </Label>
                    </Field>

                    {/* Email us label */}
                    <div className="mt-8 w-full">
                        <p className="font-semibold text-grey300">
                            {getLabel('settings.email-us')}
                        </p>
                        <div
                            className="flex w-full cursor-pointer items-center py-1"
                            onClick={onCopyToEmailClipboard}
                        >
                            <span className="mr-4 font-semibold text-grey300">
                                {SUPPORT_EMAIL}
                            </span>
                            <FaCopy size={24} className="text-grey300" />
                        </div>
                    </div>

                    {settings.isShowDevControls && (
                        <div className="mt-6 flex w-full flex-1 flex-col">
                            <TextButton
                                onClick={
                                    clearStorageState.loading
                                        ? undefined
                                        : doClearStorage
                                }
                                text="Clear storage"
                                className="mb-2.5"
                                textStyle="text-sm"
                                isDisabled={clearStorageState.loading}
                                isLoading={clearStorageState.loading}
                            />
                            {process.env.NODE_ENV === 'development' && (
                                <>
                                    <TextButton
                                        onClick={
                                            addCoinsState.loading
                                                ? undefined
                                                : doAddCoins
                                        }
                                        text="Add 500 coins"
                                        className="mb-2.5"
                                        textStyle="text-sm"
                                        isDisabled={addCoinsState.loading}
                                        isLoading={addCoinsState.loading}
                                    />
                                    <TextButton
                                        onClick={
                                            triggerErrorState.loading
                                                ? undefined
                                                : doTriggerError
                                        }
                                        text="Trigger error (Sentry test)"
                                        textStyle="text-sm"
                                        isDisabled={triggerErrorState.loading}
                                        isLoading={triggerErrorState.loading}
                                    />
                                </>
                            )}
                            {/* Skip words intro Toggle */}
                            <Field className="mt-5 flex w-full gap-2">
                                <Checkbox
                                    checked={settings.isSkipWordsIntro}
                                    onChange={handleSetIsSkipWordsIntro}
                                    className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-inset ring-white/15 data-[checked]:bg-white"
                                >
                                    <FaCheck className="hidden size-4 fill-black group-data-[checked]:block" />
                                </Checkbox>
                                <Label className="text-grey300">
                                    Skip words intro
                                </Label>
                            </Field>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default Settings;
