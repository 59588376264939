const WORD_BOX_WORDS_KEY = 'wordBoxWords';

const loadWordBoxWords = (studyLanguage: string): string[] => {
    if (!studyLanguage) {
        console.warn(
            'Study language is undefined or empty. Returning empty Word Box.'
        );
        return [];
    }

    const key = `${studyLanguage}-${WORD_BOX_WORDS_KEY}`;
    const storedWords = localStorage.getItem(key);
    if (!storedWords) {
        // No Word Box words found for this language
        return [];
    }

    try {
        const parsedWords: string[] = JSON.parse(storedWords);
        return Array.isArray(parsedWords) ? parsedWords : [];
    } catch (error) {
        console.error(
            `Failed to parse Word Box words for language "${studyLanguage}":`,
            error
        );
        return [];
    }
};

const saveWordBoxWords = (studyLanguage: string, words: string[]): void => {
    if (!studyLanguage) {
        console.warn(
            'Study language is undefined or empty. Cannot save Word Box words.'
        );
        return;
    }

    if (!Array.isArray(words)) {
        console.error('Words to save must be an array of strings.');
        return;
    }

    const key = `${studyLanguage}-${WORD_BOX_WORDS_KEY}`;
    try {
        localStorage.setItem(key, JSON.stringify(words));
    } catch (error) {
        console.error(
            `Failed to save Word Box words for language "${studyLanguage}":`,
            error
        );
    }
};

export { loadWordBoxWords, saveWordBoxWords };
