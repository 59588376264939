import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import labelData from '../../assets/content/Labels.json';

import { useSettings } from './SettingsContext';

interface LabelInfo {
    id: string;
    key: string;
    translations: { [key: string]: string };
}

interface LabelsContextType {
    getLabel: (key: string, upperCase?: boolean) => string;
    getLabelForLanguage: (
        key: string,
        languageCode: string,
        upperCase?: boolean
    ) => string;
    loadLabels: (onLoadingFinished: () => void) => void;
}

interface LabelsProviderProps {
    children: ReactNode;
}

const LabelsContext = createContext<LabelsContextType | undefined>(undefined);

export const LabelsProvider: FC<LabelsProviderProps> = ({ children }) => {
    const { settings } = useSettings();

    const [labels, setLabels] = useState<{ [lang: string]: LabelInfo }>({});

    const loadLabels = useCallback((onLoadingFinished: () => void) => {
        console.log('Loading labels...');
        const loadedLabels: { [key: string]: LabelInfo } = {};
        labelData.labels.forEach((label: LabelInfo) => {
            loadedLabels[label.key] = label;
        });
        setLabels(loadedLabels);
        console.log('Loading labels finished');
        onLoadingFinished();
    }, []);

    const getLabel = useCallback(
        (key: string, upperCase: boolean = false): string => {
            if (!settings?.uiLanguage) {
                console.warn('Settings not loaded yet');
                return key;
            }

            const label = labels[key]?.translations[settings.uiLanguage];
            if (label) {
                return upperCase ? label.toUpperCase() : label;
            }

            console.error(`Label not found for key: ${key}`);
            return key;
        },
        [labels, settings?.uiLanguage]
    );

    const getLabelForLanguage = useCallback(
        (
            key: string,
            languageCode: string,
            upperCase: boolean = false
        ): string => {
            const label = labels[key]?.translations[languageCode];
            if (label) {
                return upperCase ? label.toUpperCase() : label;
            }

            console.error(
                `Label not found for key: ${key} and language: ${languageCode}`
            );
            return key;
        },
        [labels]
    );

    const contextValue = useMemo(
        () => ({
            getLabel,
            getLabelForLanguage,
            loadLabels
        }),
        [getLabel, getLabelForLanguage, loadLabels]
    );

    return (
        <LabelsContext.Provider value={contextValue}>
            {children}
        </LabelsContext.Provider>
    );
};

export const useLabels = (): LabelsContextType => {
    const context = useContext(LabelsContext);
    if (context === undefined) {
        throw new Error('useLabels must be used within a LabelsProvider');
    }
    return context;
};
