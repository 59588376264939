import { InitDataParsed, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import axios from 'axios';

import { LeaderboardData } from '@/types/leaderboard';
import { CategoryProgressItem, LandscapeProgressItem } from '@/types/progress';
import { LanguageSettings, UserSettings } from '@/types/settings';
import { API_URL } from '@/utils/constants';

import {
    GetAllProgressResponse,
    GetGameConfigResponse,
    GetSettingsResponse,
    GetUserResponse,
    PostCoinsResponse,
    PostLandscapesProgressResponse,
    PostLanguageSettingsResponse,
    PostWordsProgressResponse,
    PutGameLevelResponse,
    PutLandscapeProgressResponse,
    PutSelectedLandscapeIdResponse,
    PutSettingsResponse,
    PutXpResponse
} from './types';

const apiClient = axios.create({
    baseURL: API_URL
});

apiClient.interceptors.request.use(
    config => {
        const { initData, initDataRaw } = retrieveLaunchParams();

        // TODO: Temporary logging of auth date info. Remove after testing.
        showAuthDateInfo(initData);

        if (initDataRaw) {
            config.headers.Authorization = `tma ${initDataRaw}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const showAuthDateInfo = (initData: InitDataParsed) => {
    try {
        if (initData && initData.authDate) {
            const authDate = initData.authDate;
            const now = new Date();
            const timeDiffMs = now.getTime() - authDate.getTime(); // Difference in milliseconds

            // Calculate total time difference in seconds
            const timeDiffSeconds = Math.floor(timeDiffMs / 1000);

            // Extract hours, minutes, and seconds
            const hours = Math.floor(timeDiffSeconds / 3600);
            const minutes = Math.floor((timeDiffSeconds % 3600) / 60);
            const seconds = timeDiffSeconds % 60;

            // Prepare text with proper singular/plural forms
            const hoursText = hours === 1 ? 'hour' : 'hours';
            const minutesText = minutes === 1 ? 'minute' : 'minutes';
            const secondsText = seconds === 1 ? 'second' : 'seconds';

            // Build an array of non-zero time parts
            const parts = [];
            if (hours > 0) parts.push(`${hours} ${hoursText}`);
            if (minutes > 0) parts.push(`${minutes} ${minutesText}`);
            if (seconds > 0 || parts.length === 0)
                parts.push(`${seconds} ${secondsText}`);

            const timeDiffText = parts.join(', ');

            console.log(`User was authenticated ${timeDiffText} ago`);
        } else {
            console.warn('authDate is not available in initData.');
        }
    } catch (error) {
        console.error('Error showing auth date info:', error);
    }
};

export const getUserApi = async (): Promise<GetUserResponse> => {
    try {
        const response = await apiClient.get<GetUserResponse>('/user');
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw new Error('Failed to fetch user');
    }
};

export const postUserApi = async (): Promise<GetUserResponse> => {
    try {
        const response = await apiClient.post<GetUserResponse>('/user');
        return response.data;
    } catch (error) {
        console.error('Error posting user:', error);
        throw new Error('Failed to post user');
    }
};

const getGameConfigApi = async (): Promise<GetGameConfigResponse> => {
    try {
        const response =
            await apiClient.get<GetGameConfigResponse>('/game-config');
        return response.data;
    } catch (error) {
        console.error('Error fetching game config:', error);
        throw new Error('Failed to fetch game config');
    }
};

const getUserSettingsApi = async (): Promise<GetSettingsResponse> => {
    try {
        const response =
            await apiClient.get<GetSettingsResponse>(`/user/settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user settings:', error);
        throw new Error('Failed to fetch user settings');
    }
};

const updateUserSettingsApi = async (
    updatedSettings: Partial<UserSettings>
): Promise<PutSettingsResponse> => {
    try {
        const response = await apiClient.put<UserSettings>(
            `/user/settings`,
            updatedSettings
        );
        return response.data;
    } catch (error) {
        console.error('Error updating user settings:', error);
        throw new Error('Failed to update user settings');
    }
};

const updateLanguageSettingsApi = async (
    lang: string,
    languageLevel: string
): Promise<PostLanguageSettingsResponse> => {
    try {
        const response = await apiClient.post<LanguageSettings>(
            `/user/${lang}/settings`,
            { languageLevel }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating language settings:', error);
        throw new Error('Failed to update language settings');
    }
};

const updateWordsProgressApi = async (
    lang: string,
    categoryProgressItems: CategoryProgressItem[]
): Promise<PostWordsProgressResponse> => {
    try {
        const response = await apiClient.post(`/user/${lang}/progress/words`, {
            categoryProgressItems
        });
        return response.data;
    } catch (error) {
        console.error('Error posting words progress:', error);
        throw new Error('Failed to post words progress');
    }
};

const updateLandscapesProgressApi = async (
    lang: string,
    landscapeProgressItem: LandscapeProgressItem
): Promise<PostLandscapesProgressResponse> => {
    try {
        const response = await apiClient.post(
            `/user/${lang}/progress/landscapes`,
            landscapeProgressItem
        );
        return response.data;
    } catch (error) {
        console.error('Error posting landscapes progress:', error);
        throw new Error('Failed to post landscapes progress');
    }
};

const updateCoinsApi = async (coins: number): Promise<PostCoinsResponse> => {
    try {
        const response = await apiClient.post(`/user/coins`, {
            coins
        });
        return response.data;
    } catch (error) {
        console.error('Error posting coins:', error);
        throw new Error('Failed to post coins');
    }
};

const updateGameLevelApi = async (
    lang: string,
    level: number
): Promise<PutGameLevelResponse> => {
    try {
        const response = await apiClient.put(`/user/${lang}/progress`, {
            gameLevel: level
        });
        return response.data;
    } catch (error) {
        console.error('Error updating game level:', error);
        throw new Error('Failed to update game level');
    }
};

const updateXpApi = async (
    lang: string,
    xp: number
): Promise<PutXpResponse> => {
    try {
        const response = await apiClient.put(`/user/${lang}/progress`, { xp });
        return response.data;
    } catch (error) {
        console.error('Error updating XP:', error);
        throw new Error('Failed to update XP');
    }
};

const updateSelectedLandscapeIdApi = async (
    lang: string,
    landscapeId: string
): Promise<PutSelectedLandscapeIdResponse> => {
    try {
        const response = await apiClient.put(`/user/${lang}/progress`, {
            selectedLandscapeId: landscapeId
        });
        return response.data;
    } catch (error) {
        console.error('Error updating selected landscape ID:', error);
        throw new Error('Failed to update selected landscape ID');
    }
};

const updateLandscapeProgressApi = async (
    lang: string,
    landscapeProgress: number
): Promise<PutLandscapeProgressResponse> => {
    try {
        const response = await apiClient.put(`/user/${lang}/progress`, {
            landscapeProgress
        });
        return response.data;
    } catch (error) {
        console.error('Error updating landscape progress:', error);
        throw new Error('Failed to update landscape progress');
    }
};

const generateInvoiceApi = async (code: string): Promise<string> => {
    try {
        const response = await axios.post(
            'https://us-central1-mylingocity.cloudfunctions.net/generateInvoice',
            {
                code
            }
        );

        return response.data.invoiceLink;
    } catch (error) {
        console.error('Error generating invoice:', error);
        throw new Error('Failed to generate invoice');
    }
};

const getLeaderboardApi = async (
    lang: string,
    top: number = 10,
    window: number = 5
): Promise<LeaderboardData> => {
    try {
        const response = await apiClient.get<LeaderboardData>(
            `/user/${lang}/leaderboard`,
            {
                params: {
                    top,
                    window
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching leaderboard:', error);
        throw new Error('Failed to fetch leaderboard');
    }
};

const getAllProgressApi = async (
    lang: string
): Promise<GetAllProgressResponse> => {
    try {
        const response = await apiClient.get<GetAllProgressResponse>(
            `/user/${lang}/progress/all`
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching full user progress:', error);
        throw new Error('Failed to fetch full user progress');
    }
};

const clearUserDataApi = async (): Promise<void> => {
    try {
        await apiClient.post('/user/clear-storage');
    } catch (error) {
        console.error('Error clearing user data:', error);
        throw new Error('Failed to clear user data');
    }
};

export {
    clearUserDataApi,
    generateInvoiceApi,
    getAllProgressApi,
    getGameConfigApi,
    getLeaderboardApi,
    getUserSettingsApi,
    updateCoinsApi,
    updateGameLevelApi,
    updateLandscapeProgressApi,
    updateLandscapesProgressApi,
    updateLanguageSettingsApi,
    updateSelectedLandscapeIdApi,
    updateUserSettingsApi,
    updateWordsProgressApi,
    updateXpApi
};
