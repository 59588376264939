import {
    createContext,
    type FC,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { useContent, useGameProgress, useSettings } from '@/context';

import { languageImages } from '../utils/images';

interface BackgroundImageContextType {
    image: string;
    setBackgroundImage: (image: string) => void;
    applyCurrentLandscape: () => void;
}

const BackgroundImageContext = createContext<
    BackgroundImageContextType | undefined
>(undefined);

interface BackgroundImageProviderProps {
    children: ReactNode;
}

export const BackgroundImageProvider: FC<BackgroundImageProviderProps> = ({
    children
}): ReactElement => {
    const { gameProgress } = useGameProgress();
    const { settings } = useSettings();
    const { landscapes } = useContent();

    const [image, setImage] = useState<string | undefined>();

    const setBackgroundImage = useCallback((image: string) => {
        setImage(image);
    }, []);

    const applyDefaultBackground = useCallback(() => {
        if (!settings?.studyLanguage) return;
        const defaultImage = languageImages[settings.studyLanguage];
        setBackgroundImage(defaultImage);
    }, [settings?.studyLanguage, setBackgroundImage]);

    const applyCurrentLandscape = useCallback(() => {
        if (!gameProgress?.selectedLandscapeId) {
            console.log('No current landscape. Applying the default one');
            applyDefaultBackground();
            return;
        }

        const landscape = landscapes.find(
            l => l.id === gameProgress.selectedLandscapeId
        );
        if (!landscape) {
            console.error(
                'Landscape not found for ID',
                gameProgress.selectedLandscapeId
            );
            return applyDefaultBackground();
        }

        setImage(landscape.imageUrl);
    }, [gameProgress?.selectedLandscapeId, landscapes, applyDefaultBackground]);

    useEffect(() => {
        applyCurrentLandscape();
    }, [gameProgress?.selectedLandscapeId, applyCurrentLandscape]);

    const contextValue = useMemo(
        () => ({
            image,
            setBackgroundImage,
            applyCurrentLandscape
        }),
        [image, setBackgroundImage, applyCurrentLandscape]
    );

    return (
        <BackgroundImageContext.Provider value={contextValue}>
            {children}
        </BackgroundImageContext.Provider>
    );
};

export const useBackgroundImage = (): BackgroundImageContextType => {
    const context = useContext(BackgroundImageContext);
    if (context === undefined) {
        throw new Error(
            'useBackgroundImage must be used within a BackgroundImageProvider'
        );
    }
    return context;
};
