import { type FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { AutoScalingText, Dialogue } from '@/components';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import Shop from '@/shop/Shop';
import { CategoryInfoWithProgress } from '@/types/category';

import coinIcon from '../../../../assets/images/icons/coin.png';
import lockIcon from '../../../../assets/images/icons/lock.png';

interface CategoryCardProps {
    category: CategoryInfoWithProgress;
    onChooseCategory: (category: CategoryInfoWithProgress) => void;
    insideList?: boolean;
}

export const CategoryCard: FC<CategoryCardProps> = ({
    category,
    onChooseCategory,
    insideList
}) => {
    const [isUnlockDialogueVisible, setUnlockDialogueVisible] = useState(false);
    const [isShowShop, setIsShowShop] = useState(false);

    const { categoryInfo, categoryProgressItem } = category;
    const { gameProgress, unlockCategory, addCoins } = useGameProgress();
    const { settings } = useSettings();
    const { gameConfig } = useGameConfig();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const progress =
        categoryProgressItem.wordProgressItems.length /
        categoryInfo.content.length;

    const locked =
        !categoryInfo.startFriendly && !categoryProgressItem.unlocked;
    const unlockCategoryPrice =
        categoryInfo.content.length * gameConfig.pricePerWordInCategory;

    const onChooseCategoryPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();

        if (locked) {
            if (gameProgress.coins >= unlockCategoryPrice) {
                setUnlockDialogueVisible(true);
            } else {
                setIsShowShop(true);
            }
        } else {
            onChooseCategory(category);
        }
    };

    const [unlockState, doUnlock] = useAsyncFn(async () => {
        try {
            // TODO: unlock and deduct should be handled in a single transaction
            // Unlock the category
            await unlockCategory(
                settings.studyLanguage,
                settings.languageLevel,
                categoryInfo.id
            );

            vibrateLight();
            emitter.emit('playSound', { sound: SoundName.Done });

            setUnlockDialogueVisible(false);

            await addCoins(-unlockCategoryPrice);
        } catch (error) {
            console.error('Error unlocking category:', unlockState.error);
            toast.error(getLabel('error.failed-to-unlock-category'));
        }
    }, [
        unlockCategory,
        addCoins,
        settings.studyLanguage,
        settings.languageLevel,
        categoryInfo.id,
        unlockCategoryPrice,
        vibrateLight
    ]);

    const onUnlockClose = () => {
        setUnlockDialogueVisible(false);
    };

    return (
        <div
            className={classNames(
                'flex flex-col items-center justify-center',
                insideList ? 'w-full' : 'w-[31%]'
            )}
        >
            <motion.div
                whileTap={{ scale: 0.95 }}
                initial={{ scale: 1 }}
                animate={{ scale: 1 }}
                className="relative aspect-square w-full"
            >
                <div
                    className={classNames(
                        'absolute top-1 aspect-square w-full rounded-[28px]',
                        locked ? 'opacity-15' : 'opacity-50'
                    )}
                    style={{ backgroundColor: categoryInfo.color }}
                ></div>
                <button
                    className="relative flex h-full w-full items-center justify-center rounded-[28px] p-5 transition-transform duration-150 ease-in-out"
                    style={{ backgroundColor: categoryInfo.color }}
                    onClick={onChooseCategoryPress}
                >
                    <img
                        src={categoryInfo.imageUrl}
                        alt={categoryInfo.name}
                        className="h-full w-full object-contain"
                    />
                    {locked && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center rounded-[28px] bg-black/60 pt-1.5">
                            <img
                                src={lockIcon}
                                alt="Locked"
                                className="mb-2 h-6"
                            />
                            <div className="flex items-center rounded-full bg-black/40 px-1 py-0.5">
                                <img
                                    src={coinIcon}
                                    alt="Coins"
                                    className="mr-1 h-6 w-6"
                                />
                                <span className="font-bold text-white">
                                    {unlockCategoryPrice}
                                </span>
                            </div>
                        </div>
                    )}
                </button>
            </motion.div>
            <div className="my-2 w-full text-center">
                <AutoScalingText
                    maxFontSize={16}
                    style={{ color: 'white', fontWeight: 'bold' }}
                    maxLines={3}
                    text={categoryInfo.name}
                />
            </div>
            <div className="relative h-6 w-full rounded-xl bg-white/15">
                <div
                    className="h-full rounded-xl bg-black/20"
                    style={{ width: `${progress * 100}%` }}
                />
                <span className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-white">
                    {`${categoryProgressItem.wordProgressItems.length}/${categoryInfo.content.length}`}
                </span>
            </div>
            <Dialogue
                title={getLabel('category.unlock-new-topic')}
                text={getLabel('category.unlocking-new-topics')}
                onButton1Press={doUnlock}
                onButton2Press={onUnlockClose}
                onClose={onUnlockClose}
                isVisible={isUnlockDialogueVisible}
                button1Text={getLabel('button.yes')}
                button2Text={getLabel('button.no')}
                outline
                button1Loading={unlockState.loading}
            />
            <Shop
                isVisible={isShowShop}
                onClose={() => setIsShowShop(false)}
                notEnoughCoinsMode={true}
            />
        </div>
    );
};
