import { type FC } from 'react';
import classNames from 'classnames';

import { questionStyles } from './questionStyles';

interface QuestionInstructionsProps {
    text: string;
}

const QuestionInstructions: FC<QuestionInstructionsProps> = ({ text }) => {
    return (
        <div
            className={classNames(
                questionStyles.container,
                'absolute top-1 bg-grey'
            )}
        >
            <p className={questionStyles.fontBold}>{text}</p>
        </div>
    );
};

export default QuestionInstructions;
