import deFlag from '../../assets/images/flags/flag-de.png';
import enFlag from '../../assets/images/flags/flag-en.png';
import esFlag from '../../assets/images/flags/flag-es.png';
import fiFlag from '../../assets/images/flags/flag-fi.png';
import frFlag from '../../assets/images/flags/flag-fr.png';
import itFlag from '../../assets/images/flags/flag-it.png';
import ruFlag from '../../assets/images/flags/flag-ru.png';
import deLandscape from '../../assets/images/landscapes/default/default-de.webp';
import enLandscape from '../../assets/images/landscapes/default/default-en.webp';
import esLandscape from '../../assets/images/landscapes/default/default-es.webp';
import fiLandscape from '../../assets/images/landscapes/default/default-fi.webp';
import frLandscape from '../../assets/images/landscapes/default/default-fr.webp';
import itLandscape from '../../assets/images/landscapes/default/default-it.webp';
import ruLandscape from '../../assets/images/landscapes/default/default-ru.webp';

export const flagImages: Record<string, string> = {
    de: deFlag,
    en: enFlag,
    es: esFlag,
    fi: fiFlag,
    fr: frFlag,
    it: itFlag,
    ru: ruFlag
};

export const languageImages: Record<string, string> = {
    en: enLandscape,
    es: esLandscape,
    fi: fiLandscape,
    de: deLandscape,
    it: itLandscape,
    fr: frLandscape,
    ru: ruLandscape
};
