import {
    type FC,
    forwardRef,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { FaForward } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { Dialogue, ModalInfo, TooltipContent } from '@/components';
import MagicWandIcon from '@/components/icons/MagicWandIcon';
import VolumeIcon from '@/components/icons/VolumeIcon';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import emitter, { ExtraWordFoundPayload } from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import Shop from '@/shop/Shop';
import { COLORS } from '@/utils/colors';

import bucket from '../../../assets/images/icons/bucket.png';
import coinIcon from '../../../assets/images/icons/coin.png';
import exit from '../../../assets/images/icons/exit.png';
import question from '../../../assets/images/icons/question.png';

import { PuzzleType } from './types';
import WordBox from './WordBox';

import 'tippy.js/animations/scale-subtle.css';

interface HintsPanelProps {
    puzzleType: PuzzleType;
    onLeavePuzzle: () => void;
    isReview?: boolean;
}

const HintsPanel: FC<HintsPanelProps> = ({
    puzzleType,
    onLeavePuzzle,
    isReview = false
}) => {
    const { getLabel } = useLabels();
    const [isPauseDialogVisible, setIsPauseDialogVisible] = useState(false);
    const { gameConfig } = useGameConfig();
    const {
        settings,
        updateIsMagicWandTooltipNeeded,
        updateIsAudioHintTooltipNeeded
    } = useSettings();
    const { gameProgress, addCoins, wordBoxWords, addWordToWordBox } =
        useGameProgress();
    const { navigate } = useNavigation();

    const [isMagicWandTooltipVisible, setIsMagicWandTooltipVisible] = useState(
        gameProgress.gameLevel === 2 && settings.isMagicWandTooltipNeeded
    );
    const [isAudioHintTooltipVisible, setIsAudioHintTooltipVisible] =
        useState(false);

    const [isShowShop, setIsShowShop] = useState(false);
    const [isShowInstructions, setIsShowInstructions] = useState(false);
    const [isShowWordBox, setIsShowWordBox] = useState(false);
    const [extraWord, setExtraWord] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [extraWordCoordinates, setExtraWordCoordinates] = useState<
        { x: number; y: number } | undefined
    >(undefined);

    const magicWandButtonRef = useRef<HintButtonHandle>(null);
    const audioHintButtonRef = useRef<HintButtonHandle>(null);

    const bucketButtonRef = useRef<HintButtonHandle>(null);

    useEffect(() => {
        const onAudioHintWordSelected = ({ wordId }) => {
            if (gameProgress.coins < gameConfig.audioHintPrice) {
                setIsShowShop(true);
            } else {
                emitter.emit('audioHintPlayWord', { wordId });
                addCoins(-gameConfig.audioHintPrice);
                audioHintButtonRef.current?.animateSpendCoins(
                    gameConfig.audioHintPrice
                );
            }
        };

        const onMagicWandHintTileFound = () => {
            if (gameProgress.coins < gameConfig.magicWandHintPrice) {
                setIsShowShop(true);
            } else {
                emitter.emit('magicWandHintShowTile');
                addCoins(-gameConfig.magicWandHintPrice);
                magicWandButtonRef.current?.animateSpendCoins(
                    gameConfig.magicWandHintPrice
                );
            }
        };

        const onMagicWandHintCannotFind = () => {
            emitter.emit('playSound', { sound: SoundName.Wrong });
            toast(getLabel('hint.all-letters-are-found'));
        };

        emitter.on('audioHintWordSelected', onAudioHintWordSelected);
        emitter.on('magicWandHintTileFound', onMagicWandHintTileFound);
        emitter.on('magicWandHintCannotFind', onMagicWandHintCannotFind);

        return () => {
            emitter.off('audioHintWordSelected', onAudioHintWordSelected);
            emitter.off('magicWandHintTileFound', onMagicWandHintTileFound);
            emitter.off('magicWandHintCannotFind', onMagicWandHintCannotFind);
        };
    }, [gameProgress.coins]);

    useEffect(() => {
        const onExtraWordFound = (
            extraWordFoundPayload: ExtraWordFoundPayload
        ) => {
            setExtraWord(extraWordFoundPayload.word);
            setIsAnimating(true);
            setExtraWordCoordinates(extraWordFoundPayload.coordinates);

            emitter.emit('playSound', { sound: SoundName.Card });

            setTimeout(() => {
                setIsAnimating(true);
                const updatedWords = addWordToWordBox(
                    settings.studyLanguage,
                    extraWordFoundPayload.word
                );
                if (updatedWords.length >= 10) {
                    emitter.emit('playSound', { sound: SoundName.Notice });
                }

                if (bucketButtonRef.current) {
                    bucketButtonRef.current.animate();
                }
            }, 500);
        };

        emitter.on('extraWordFound', onExtraWordFound);

        return () => {
            emitter.off('extraWordFound', onExtraWordFound);
        };
    }, [addWordToWordBox, wordBoxWords]);

    const onPausePress = () => setIsPauseDialogVisible(true);
    const onAudioHintPress = () => {
        if (
            !isAudioHintTooltipVisible &&
            gameProgress.coins >= gameConfig.audioHintPrice
        ) {
            emitter.emit('audioHintRequested');
        } else if (gameProgress.coins < gameConfig.audioHintPrice) {
            setIsShowShop(true);
        }
    };

    const onMagicWandHintPress = () => {
        if (
            !isMagicWandTooltipVisible &&
            gameProgress.coins >= gameConfig.magicWandHintPrice
        ) {
            emitter.emit('magicWandHintRequested');
        } else if (gameProgress.coins < gameConfig.magicWandHintPrice) {
            setIsShowShop(true);
        }
    };

    const onFinishStep = () => emitter.emit('forceGameStepComplete');
    const onLeavePuzzlePress = () => onLeavePuzzle();
    const onSeeNewWordsAgainPress = () => navigate('WordsIntro');
    const onHelpPress = () => setIsShowInstructions(true);
    const onBucketPress = () => setIsShowWordBox(true);

    const getInstructionsText = () => {
        switch (puzzleType) {
            case PuzzleType.BasicWithTranslation:
            case PuzzleType.BasicWithoutTranslation:
                return getLabel('puzzle.find-words');
            case PuzzleType.ChooseTranslation:
                return getLabel('puzzle.find-words-and-choose-translation');
            default:
                return 'Instructions are missing';
        }
    };

    return (
        <>
            {extraWord && isAnimating && (
                <motion.div
                    initial={{
                        x: extraWordCoordinates?.x,
                        y: extraWordCoordinates?.y,
                        scale: 1
                    }}
                    animate={{
                        x: bucketButtonRef.current
                            ? bucketButtonRef.current.getBoundingClientRect()
                                  .left
                            : 0,
                        y: bucketButtonRef.current
                            ? bucketButtonRef.current.getBoundingClientRect()
                                  .top
                            : 0,
                        scale: 0.4
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut'
                    }}
                    className="fixed left-0 top-0 z-[1000] rounded-lg bg-grey/80 px-2 py-1 font-bold tracking-widest text-white"
                    onAnimationComplete={() => {
                        setExtraWord(null);
                        setIsAnimating(false);
                    }}
                >
                    {extraWord}
                </motion.div>
            )}
            {(isMagicWandTooltipVisible || isAudioHintTooltipVisible) && (
                <div className="fixed inset-0 z-10 bg-black opacity-70"></div>
            )}
            <div className="flex justify-between">
                <div className="flex gap-2">
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel('hint.magic-wand')}
                                text={getLabel('hint.magic-wand-text')}
                            />
                        }
                        visible={isMagicWandTooltipVisible}
                        placement="top-start"
                        onClickOutside={() => {
                            setIsMagicWandTooltipVisible(false);
                            void updateIsMagicWandTooltipNeeded(false);
                            setIsAudioHintTooltipVisible(true);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div>
                            <HintButton
                                ref={magicWandButtonRef}
                                onButtonPress={onMagicWandHintPress}
                                icon={
                                    <MagicWandIcon
                                        width={36}
                                        height={36}
                                        color={COLORS.white}
                                    />
                                }
                                price={gameConfig.magicWandHintPrice}
                                className={
                                    isMagicWandTooltipVisible ? 'z-20' : ''
                                }
                            />
                        </div>
                    </Tippy>
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel('hint.audio-hint')}
                                text={getLabel('hint.audio-hint-text')}
                            />
                        }
                        visible={isAudioHintTooltipVisible}
                        placement="top-start"
                        onClickOutside={() => {
                            setIsAudioHintTooltipVisible(false);
                            void updateIsAudioHintTooltipNeeded(false);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div>
                            <HintButton
                                ref={audioHintButtonRef}
                                onButtonPress={onAudioHintPress}
                                icon={
                                    <VolumeIcon
                                        width={22}
                                        height={22}
                                        color={COLORS.white}
                                    />
                                }
                                price={gameConfig.audioHintPrice}
                                className={
                                    isAudioHintTooltipVisible ? 'z-20' : ''
                                }
                            />
                        </div>
                    </Tippy>
                    {settings.isShowDevControls && !isReview && (
                        <HintButton
                            onButtonPress={onFinishStep}
                            icon={<FaForward size={20} color="white" />}
                        />
                    )}
                </div>
                <div className="flex gap-2">
                    <HintButton
                        ref={bucketButtonRef}
                        onButtonPress={onBucketPress}
                        icon={<img src={bucket} className="h-5" />}
                        badge={wordBoxWords.length}
                    />
                    <HintButton
                        onButtonPress={onHelpPress}
                        icon={<img src={question} className="h-5" />}
                    />
                    <HintButton
                        onButtonPress={onPausePress}
                        icon={<img src={exit} className="h-5" />}
                    />
                </div>
            </div>
            <Shop
                isVisible={isShowShop}
                onClose={() => setIsShowShop(false)}
                notEnoughCoinsMode={true}
            />
            <WordBox
                isVisible={isShowWordBox}
                onClose={() => setIsShowWordBox(false)}
            />
            <ModalInfo
                isVisible={isShowInstructions}
                onClose={() => setIsShowInstructions(false)}
                description={getInstructionsText()}
            />
            <Dialogue
                isVisible={isPauseDialogVisible}
                onClose={() => setIsPauseDialogVisible(false)}
                title={getLabel('puzzle.game-on-hold')}
                text={
                    isReview
                        ? getLabel('puzzle.are-you-sure')
                        : getLabel('puzzle.if-you-leave')
                }
                onButton1Press={onLeavePuzzlePress}
                onButton2Press={isReview ? undefined : onSeeNewWordsAgainPress}
                button1Text={getLabel('puzzle.leave-this-puzzle')}
                button2Text={
                    isReview
                        ? undefined
                        : getLabel('puzzle.see-new-words-again')
                }
            />
        </>
    );
};

interface HintButtonProps {
    onButtonPress: () => void;
    icon: ReactNode;
    badge?: number;
    price?: number;
    className?: string;
}

export interface HintButtonHandle {
    animate: () => void;
    animateSpendCoins: (price: number) => void;
    getBoundingClientRect: () => DOMRect | undefined;
}

interface SpendAnimation {
    id: number;
    price: number;
}

const HintButton = forwardRef<HintButtonHandle, HintButtonProps>(
    ({ onButtonPress, icon, price, badge, className }, ref) => {
        const controls = useAnimation();
        const { vibrateLight } = useVibration();
        const [spendAnimations, setSpendAnimations] = useState<
            SpendAnimation[]
        >([]);
        const [animationCounter, setAnimationCounter] = useState(0);

        const buttonRef = useRef<HTMLButtonElement>(null);

        const handlePress = () => {
            vibrateLight();
            emitter.emit('playSound', { sound: SoundName.Click2 });
            onButtonPress();
        };

        useImperativeHandle(ref, () => ({
            animate() {
                controls.start({
                    scale: [1, 1.2, 1],
                    transition: { duration: 0.3, ease: 'easeInOut' }
                });
            },
            animateSpendCoins(price: number) {
                setAnimationCounter(prev => prev + 1);
                setSpendAnimations(prevAnimations => [
                    ...prevAnimations,
                    { id: animationCounter, price }
                ]);
            },
            getBoundingClientRect() {
                return (
                    buttonRef.current?.getBoundingClientRect() || new DOMRect()
                );
            }
        }));

        return (
            <motion.button
                onClick={handlePress}
                className={classNames(
                    'relative flex h-9 w-9 items-center justify-center rounded-xl bg-orange',
                    className
                )}
                whileTap={{ scale: 0.95 }}
                initial={{ scale: 1 }}
                animate={controls}
                ref={buttonRef}
            >
                <div className="flex items-center justify-center">{icon}</div>
                {price && (
                    <div className="absolute mt-12 flex h-5 items-center justify-around rounded-md bg-black/50 pr-1">
                        <img
                            src={coinIcon}
                            alt="Coin Icon"
                            className="h-4 w-4"
                        />
                        <span className="text-xs font-bold text-white">
                            {price}
                        </span>
                    </div>
                )}
                {badge !== undefined && (
                    <div className="bg-red-500 absolute left-1/2 top-5 flex h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full">
                        <span className="text-xs font-bold text-orange">
                            {badge}
                        </span>
                    </div>
                )}
                {spendAnimations.map(anim => (
                    <motion.div
                        key={anim.id}
                        initial={{ opacity: 1, y: 0 }}
                        animate={{ opacity: 0, y: -30 }}
                        transition={{ duration: 1.25 }}
                        className="absolute"
                        style={{
                            top: '-20px',
                            left: '-5px',
                            transform: 'translateX(-50%)',
                            pointerEvents: 'none' // Prevents interaction
                        }}
                        onAnimationComplete={() => {
                            // Remove animation from the array once it completes
                            setSpendAnimations(prevAnimations =>
                                prevAnimations.filter(a => a.id !== anim.id)
                            );
                        }}
                    >
                        <div className="flex items-center">
                            <img
                                src={coinIcon}
                                alt="Coin Icon"
                                className="h-6 w-6"
                            />
                            <span className="font-bold text-white">
                                -{anim.price}
                            </span>
                        </div>
                    </motion.div>
                ))}
            </motion.button>
        );
    }
);

HintButton.displayName = 'HintButton';

export default HintsPanel;
