import { type FC } from 'react';
import { useAsync } from 'react-use';
import classNames from 'classnames';

import { getLeaderboardApi } from '@/api/client';
import { HeaderWithCloseButton } from '@/components';
import { useLabels, useNavigation, useSettings } from '@/context';
import { LeaderboardPlayer } from '@/types/leaderboard';

const LeaderboardScreen: FC = () => {
    const { closeProfile } = useNavigation();
    const { settings } = useSettings();
    const { getLabel } = useLabels();

    // const [leaderboardType, setLeaderboardType] = useState<
    //     'weekly' | 'all-time'
    // >('weekly');

    const top = 10;
    const windowSize = 3;

    const {
        loading,
        error,
        value: leaderboardData
    } = useAsync(
        () => getLeaderboardApi(settings.studyLanguage, top, windowSize),
        [settings.studyLanguage, top, windowSize]
    );

    const generateContent = () => {
        if (loading) {
            return (
                <div className="flex h-full items-center justify-center">
                    <p className="text-white">
                        {getLabel('leaderboard.loading-leaderboard')}
                    </p>
                </div>
            );
        }

        if (error) {
            return (
                <div className="flex h-full items-center justify-center">
                    <p className="text-red-500">
                        {getLabel('leaderboard.failed-to-load-leaderboard')}
                    </p>
                </div>
            );
        }

        if (!leaderboardData) {
            return (
                <div className="flex h-full items-center justify-center">
                    <p className="text-white">
                        {getLabel('leaderboard.no-leaderboard-data-available')}
                    </p>
                </div>
            );
        }

        const { topPlayers, currentPlayer, surroundingPlayers } =
            leaderboardData || {};

        // 1. Merge all players into one array
        const mergedPlayers: LeaderboardPlayer[] = [
            ...topPlayers,
            currentPlayer,
            ...surroundingPlayers.before,
            ...surroundingPlayers.after
        ];

        // 2. Remove duplicates based on rank
        const uniquePlayersMap = new Map<number, LeaderboardPlayer>();
        mergedPlayers.forEach(player => {
            if (!uniquePlayersMap.has(player.rank)) {
                uniquePlayersMap.set(player.rank, player);
            }
        });
        const uniquePlayers = Array.from(uniquePlayersMap.values());

        // 3. Sort the players by rank ascending
        uniquePlayers.sort((a, b) => a.rank - b.rank);

        // 4. Build the renderable list with "..." where there are gaps
        const renderList: (LeaderboardPlayer | 'gap')[] = [];

        for (let i = 0; i < uniquePlayers.length; i++) {
            const currentPlayer = uniquePlayers[i];
            const previousPlayer = uniquePlayers[i - 1];

            if (i > 0) {
                const rankDifference = currentPlayer.rank - previousPlayer.rank;

                if (rankDifference > 1) {
                    // Insert "..." to indicate a gap
                    renderList.push('gap');
                }
            }

            renderList.push(currentPlayer);
        }

        return (
            <div className="flex h-full flex-grow flex-col pt-4">
                {/* <div className="mb-2 flex h-12 w-full rounded-xl bg-white/10 font-bold uppercase tracking-wide text-white">
                    <ToggleButton
                        isActive={leaderboardType === 'weekly'}
                        label="Week"
                        onClick={() => setLeaderboardType('weekly')}
                    />
                    <ToggleButton
                        isActive={leaderboardType === 'all-time'}
                        label="All Time"
                        onClick={() => setLeaderboardType('all-time')}
                    />
                </div> */}
                <div className="flex-grow overflow-y-auto pb-6 pt-2">
                    {renderList.map((item, index) => {
                        if (item === 'gap') {
                            return (
                                <div
                                    key={`gap-${index}`}
                                    className="-mt-2 mb-3 flex w-full items-center justify-center"
                                >
                                    <h2 className="text-white">...</h2>
                                </div>
                            );
                        }

                        const player = item as LeaderboardPlayer;
                        const isCurrent = player.rank === currentPlayer.rank;

                        return (
                            <PlayerRow
                                key={player.rank}
                                player={player}
                                isCurrent={isCurrent}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseButton
                onClose={closeProfile}
                title={getLabel('leaderboard.leaderboard')}
            />
            {generateContent()}
        </div>
    );
};

export default LeaderboardScreen;

interface PlayerRowProps {
    player: LeaderboardPlayer;
    isCurrent: boolean;
}

const PlayerRow: FC<PlayerRowProps> = ({ player, isCurrent }) => {
    return (
        <div
            className={classNames(
                'mb-3 flex w-full items-center justify-between rounded-xl px-4 py-2',
                isCurrent
                    ? 'border border-orange/10 bg-orange/10 text-orange'
                    : 'bg-white/10 text-white'
            )}
        >
            <span className="flex items-center gap-3 font-bold tracking-wide">
                <span className="text-lg">{player.rank}</span>
                <span className="player-name">{player.username}</span>
            </span>
            <span className="text-xs">XP {player.xp.toLocaleString()}</span>
        </div>
    );
};

// interface ToggleButtonProps {
//     isActive: boolean;
//     label: string;
//     onClick: () => void;
// }

// const ToggleButton: FC<ToggleButtonProps> = ({ isActive, label, onClick }) => {
//     return (
//         <button
//             type="button"
//             className={classNames(
//                 'flex h-full w-1/2 items-center justify-center rounded-xl font-bold uppercase tracking-wide text-white transition-colors duration-200',
//                 {
//                     'bg-orange': isActive,
//                     'bg-transparent hover:bg-white/20': !isActive
//                 }
//             )}
//             onClick={onClick}
//             aria-pressed={isActive}
//         >
//             {label}
//         </button>
//     );
// };
