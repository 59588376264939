import { type FC, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { WordInfoLocalized } from '@/types/category';

interface WordPanelProps {
    word: WordInfoLocalized;
    found: boolean;
    onClick?: () => void;
}

const WordView: FC<WordPanelProps> = ({ word, found, onClick }) => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start({
            color: found ? word.categoryColor : '#ffffff',
            transition: { duration: 0.5 }
        });
    }, [found, word.categoryColor, controls]);

    return (
        <div
            className="mb-10 flex flex-col items-center justify-center rounded-3xl bg-black bg-opacity-20 px-8 py-4"
            onClick={onClick}
        >
            <motion.div animate={controls}>
                <div className="mb-0.5 text-2xl font-semibold tracking-wider">
                    {word.word}
                </div>
            </motion.div>
            <div className="text-xl tracking-wider text-grey50">
                {word.translation}
            </div>
        </div>
    );
};

export default WordView;
