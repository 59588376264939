import { type FC, memo, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { ProgressBar } from '@/components';
import {
    useGameProgress,
    useNavigation,
    useNewWordsGameSession,
    useSettings
} from '@/context';
import useDisableVerticalSwipe from '@/hooks/useDisableVertialSwipe';
import { useVibration } from '@/hooks/useVibration';

import emitter, { CorrectAnswerGivenPayload } from '../../events/emitter';
import { getUniqueCleanWords } from '../../utils/categories';
import { COLORS } from '../../utils/colors';

import GameStep from './GameStep';
import HintsPanel from './HintsPanel';
import { getPuzzleTypeByStepNumber, PuzzleType } from './types';

interface PuzzleScreenProps {
    showCursor: boolean;
}

// prevent rerendering of the component after gameSessionProgress is initialized
const PuzzleScreen: FC<PuzzleScreenProps> = memo(({ showCursor }) => {
    useDisableVerticalSwipe();

    const { vibrateLight } = useVibration();
    const { navigate } = useNavigation();
    const { settings } = useSettings();
    const { gameSession, goToNextGameSessionStep, updateGameSessionCoins } =
        useNewWordsGameSession();
    const { gameProgress } = useGameProgress();

    const [previousBoardData, setPreviousBoardData] = useState(null);

    const [currentStepIndex, setCurrentStepIndex] = useState(
        gameSession.currentStep
    );

    const puzzleData = useMemo(() => {
        const puzzleType = getPuzzleTypeByStepNumber(gameSession.currentStep);

        const wordsToPlay = [];
        switch (gameSession.currentStep) {
            case 1:
            case 2:
                wordsToPlay.push(...gameSession.wordsToStudy);
                break;
            case 3:
                wordsToPlay.push(
                    ...gameSession.wordsToStudy,
                    ...gameSession.wordsToReview3
                );
                break;
            case 4:
                wordsToPlay.push(
                    ...gameSession.wordsToReview1,
                    ...gameSession.wordsToReview7
                );
                break;
            default:
                break;
        }

        const uniqueWordsToPlay = getUniqueCleanWords(
            wordsToPlay,
            settings.studyLanguage
        );
        const allWordsAreFromTheSameCategory = uniqueWordsToPlay.every(
            word => word.categoryId === gameSession.category.categoryInfo.id
        );

        const selectedTileColor = allWordsAreFromTheSameCategory
            ? gameSession.category.categoryInfo.color
            : COLORS.blue;

        return {
            puzzleType,
            wordsToPlay: uniqueWordsToPlay,
            selectedTileColor
        };
    }, [gameSession.currentStep, settings.studyLanguage]);

    // handles the case when there are no words to play (for instance, new words to review on step 4 in the beginning of the game (game level 1))
    useEffect(() => {
        if (
            !gameSession.finished &&
            puzzleData.puzzleType !== PuzzleType.Unknown &&
            !puzzleData.wordsToPlay.length
        ) {
            goToNextGameSessionStep();
        }
    }, [puzzleData, gameSession.finished]);

    useEffect(() => {
        const handleCorrectAnswerGiven = ({
            word
        }: CorrectAnswerGivenPayload) => {
            updateGameSessionCoins(word.word.length);
            if (puzzleData.puzzleType !== PuzzleType.ChooseTranslation) {
                emitter.emit('playSound', { sound: SoundName.Success });
                vibrateLight();
            }
        };

        const handleInvalidWordSelected = () => {
            emitter.emit('playSound', { sound: SoundName.Wrong });
        };

        emitter.on('correctAnswerGiven', handleCorrectAnswerGiven);
        emitter.on('invalidWordSelected', handleInvalidWordSelected);

        return () => {
            emitter.off('correctAnswerGiven', handleCorrectAnswerGiven);
            emitter.off('invalidWordSelected', handleInvalidWordSelected);
        };
    }, [puzzleData]);

    useEffect(() => {
        if (gameSession.finished) {
            setCurrentStepIndex(gameSession.currentStep + 1);
            setTimeout(() => {
                navigate('Results');
            }, 750);
        } else {
            setCurrentStepIndex(gameSession.currentStep);
        }
    }, [gameSession, settings?.studyLanguage]);

    const onAllWordsFound = () => {
        goToNextGameSessionStep();
    };

    const onBoardDataReady = boardData => {
        setPreviousBoardData(boardData);
    };

    const onLeavePuzzle = () => {
        navigate('ChooseCategory');
    };

    if (
        puzzleData.puzzleType === PuzzleType.Unknown ||
        !puzzleData.wordsToPlay?.length
    ) {
        return null;
    }

    const totalSteps =
        gameProgress.gameLevel === 1 ? 3 : gameSession.totalSteps;

    return (
        <div className="flex h-full flex-col justify-center">
            <div className="-mt-2.5 mb-1 h-2">
                <ProgressBar
                    progressBarValue={(currentStepIndex - 1) / totalSteps}
                    bgColor="orange"
                    borderColor="border-none"
                    unfilledColor="bg-grey700/30"
                    height="h-1.5"
                />
            </div>
            <GameStep
                key={gameSession.currentStep}
                puzzleData={puzzleData}
                studyLanguage={settings.studyLanguage}
                onAllWordsFound={onAllWordsFound}
                previousBoardData={previousBoardData}
                onBoardDataReady={onBoardDataReady}
                showCursor={showCursor}
            />
            <motion.div
                className="mb-2 mt-5 px-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
            >
                <HintsPanel
                    puzzleType={puzzleData.puzzleType}
                    onLeavePuzzle={onLeavePuzzle}
                />
            </motion.div>
        </div>
    );
});

PuzzleScreen.displayName = 'PuzzleScreen';

const PuzzleScreenWrapper = () => {
    const { currentCategory, wordsToStudy, gameProgress, getWordsByLevel } =
        useGameProgress();

    const { gameSession, initGameSession } = useNewWordsGameSession();

    const wordsToReview1 = getWordsByLevel(gameProgress.gameLevel - 1);
    const wordsToReview3 = getWordsByLevel(gameProgress.gameLevel - 3);
    const wordsToReview7 = getWordsByLevel(gameProgress.gameLevel - 7);

    useEffect(() => {
        if (!currentCategory) return;

        initGameSession(
            currentCategory,
            wordsToStudy,
            wordsToReview1,
            wordsToReview3,
            wordsToReview7
        );
    }, [currentCategory, wordsToStudy]);

    if (!gameSession.isReadyToPlay) return null;

    const showCursor =
        gameProgress.gameLevel === 1 && gameSession.currentStep <= 2;

    return <PuzzleScreen showCursor={showCursor} />;
};

export default PuzzleScreenWrapper;
