import { FC, useState } from 'react';
import { useReward } from 'react-rewards';
import { toast } from 'react-toastify';
import { initInvoice } from '@telegram-apps/sdk';

import { generateInvoiceApi } from '@/api/client';
import { SoundName } from '@/audio/AudioManager';
import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import Modal from '@/components/Modal';
import { useGameConfig, useGameProgress, useLabels } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';

import coinIcon from '../../assets/images/icons/coin.png';
import star from '../../assets/images/star.png';

interface ShopProps {
    isVisible: boolean;
    onClose: () => void;
    notEnoughCoinsMode?: boolean;
}

interface ShopItemProps {
    coins: number;
    starsAmount: number;
    code: string;
    reward: () => void;
}

const ShopItem: FC<ShopItemProps> = ({ coins, starsAmount, code, reward }) => {
    const { getLabel } = useLabels();
    const { addCoins } = useGameProgress();
    const [isLoading, setIsLoading] = useState(false);

    const handlePurchase = async () => {
        try {
            setIsLoading(true);

            const invoice = initInvoice();

            const invoiceLink = await generateInvoiceApi(code);
            console.log('Invoice link:', invoiceLink);
            const status = await invoice.open(invoiceLink, 'url');
            if (status === 'paid') {
                console.log('Payment successful!');
                emitter.emit('playSound', { sound: SoundName.Coin });
                reward();
                await addCoins(coins);
            } else if (status === 'failed') {
                console.log('Payment failed:', status);
                toast.error(getLabel('shop.payment-failed'));
            }
        } catch (error) {
            console.error('Processing invoice error:', error);
            toast.error(getLabel('shop.processing-invoice-error'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-between rounded-2xl bg-[#E1A814]/10 px-4 py-3">
            <div className="flex items-center">
                <img src={coinIcon} alt="LingoCoins" className="mr-2 h-8 w-8" />
                <span className="text-xl font-extrabold text-[#FFC328]">
                    +{coins}
                </span>
            </div>
            <TextButton
                className="min-w-24"
                bgColor="bg-[#E1A814]"
                textStyle="text-white200"
                onClick={handlePurchase}
                isLoading={isLoading}
            >
                <div className="flex items-center justify-center gap-2">
                    {starsAmount}
                    <img src={star} className="h-6 pb-0.5"></img>
                </div>
            </TextButton>
        </div>
    );
};

const Shop: FC<ShopProps> = ({ isVisible, onClose, notEnoughCoinsMode }) => {
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { gameConfig } = useGameConfig();

    const { reward } = useReward('rewardId', 'confetti', {
        angle: 110,
        lifetime: 600,
        elementSize: 6,
        elementCount: 350,
        startVelocity: 45,
        colors: ['#FAAC38', '#4FC0E8'],
        spread: 40,
        decay: 0.96
    });

    const shopItems = gameConfig?.shopItems || [];

    const onCloseClick = () => {
        vibrateLight();
        onClose();
    };

    return (
        <>
            <div className="absolute -right-24 -top-4 z-20" id="rewardId"></div>

            <Modal isVisible={isVisible} onClose={onCloseClick}>
                <div className={commonStyles.modalPanel}>
                    <div className="flex items-start justify-between">
                        <div>
                            {notEnoughCoinsMode ? (
                                <h2 className="mb-8 text-lg text-white">
                                    {getLabel(
                                        'shop.you-dont-have-enough-coins'
                                    )}
                                </h2>
                            ) : (
                                <>
                                    <h1>{getLabel('shop')}</h1>
                                    <p className="mb-8 text-lg text-white">
                                        {getLabel('lingocoins')}
                                    </p>
                                </>
                            )}
                        </div>
                        <button
                            onClick={onClose}
                            aria-label="Close"
                            className="mt-2"
                        >
                            <CloseIcon height={16} width={16} color="white" />
                        </button>
                    </div>

                    <div className="flex w-full flex-col gap-4">
                        {shopItems.map(item => (
                            <ShopItem
                                key={item.code}
                                coins={item.coins}
                                starsAmount={item.priceInStars.amount}
                                code={item.code}
                                reward={reward}
                            />
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Shop;
