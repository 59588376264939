import { type FC } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import { SoundName } from '@/audio/AudioManager';
import { ProgressBar, TextButton } from '@/components';
import CloseIcon from '@/components/icons/CloseIcon';
import { useGameProgress, useLabels, useSettings } from '@/context';
import emitter from '@/events/emitter';
import { commonStyles } from '@/styles/commonStyles';
import { COLORS } from '@/utils/colors';

import Modal from '../../components/Modal';

interface BucketProps {
    isVisible: boolean;
    onClose: () => void;
}

const Bucket: FC<BucketProps> = ({ isVisible, onClose }) => {
    const { getLabel } = useLabels();
    const { claimWordBoxReward, wordBoxWords } = useGameProgress();
    const { settings } = useSettings();

    const [handleClaimRewardState, doHandleClaimReward] =
        useAsyncFn(async () => {
            try {
                await claimWordBoxReward(settings.studyLanguage);
                onClose();
            } catch (error) {
                console.error('Error claiming reward:', error);

                toast.error(getLabel('error.claim-reward-failed'));
                throw error;
            }
        }, [claimWordBoxReward, onClose, getLabel]);

    const onClaimReward = () => {
        if (handleClaimRewardState.loading) return;

        emitter.emit('playSound', { sound: SoundName.Coin });
        doHandleClaimReward();
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <div className={`${commonStyles.modalPanel} flex flex-col`}>
                {/* Header */}
                <div className="flex items-start justify-between">
                    <h1 className="cursor-pointer">
                        {getLabel('puzzle.word-box')}
                    </h1>
                    <button
                        onClick={onClose}
                        aria-label="Close"
                        className="mt-2"
                    >
                        <CloseIcon height={16} width={16} color="white" />
                    </button>
                </div>

                {/* Instruction */}
                <div className="mb-5">
                    <p className="mb-2 mt-4 text-white">
                        {wordBoxWords.length >= 10
                            ? getLabel('puzzle.your-word-box-is-full')
                            : getLabel('puzzle.collect-10-extra-words')}
                    </p>
                    <ProgressBar
                        progressBarValue={wordBoxWords.length}
                        height="h-2"
                        bgColor={COLORS.orange}
                        unfilledColor="bg-white/25"
                        borderColor="border-none"
                    />
                    <p className="w-full text-center text-sm text-white">
                        {wordBoxWords.length}/10
                    </p>
                </div>

                {/* Content Container */}
                <div className="mt-4 flex min-h-20 flex-1 items-center text-white">
                    {wordBoxWords.length === 0 ? (
                        <div className="flex w-full items-center justify-center">
                            {getLabel('puzzle.your-word-box-is-empty')}
                        </div>
                    ) : (
                        <div className="flex flex-wrap gap-2">
                            {wordBoxWords.map((word, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-start rounded bg-gray-700 px-2 py-1"
                                >
                                    <span>{word}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Claim Reward Button */}
            {wordBoxWords.length >= 10 && (
                <TextButton
                    className="mt-4 w-full"
                    bgColor="bg-[#E1A814]"
                    textStyle="text-white200"
                    onClick={onClaimReward}
                    isLoading={handleClaimRewardState.loading}
                >
                    {getLabel('puzzle.claim-500-xp')}
                </TextButton>
            )}
        </Modal>
    );
};

export default Bucket;
