import { delay, http, HttpResponse } from 'msw';

import { LeaderboardData } from '@/types/leaderboard';
import {
    CategoryProgressItem,
    GameProgressValues,
    LandscapeProgressItem
} from '@/types/progress';
import { LanguageSettings, UserSettings } from '@/types/settings';
import { API_URL } from '@/utils/constants';

import {
    addUser,
    checkIfUserExists,
    clearStorage,
    getLanguageProgress,
    loadLandscapesProgress,
    loadProgress,
    loadSettings,
    loadUser,
    loadWordsProgress,
    saveCoins,
    saveGameLevel,
    saveLandscapeId,
    saveLandscapeProgress,
    saveLandscapesProgress,
    saveLanguageLevel,
    saveUserSettings,
    saveWordsProgress,
    saveXp
} from './mockDb';
import {
    GetAllProgressResponse,
    GetGameConfigResponse,
    GetLeaderboardResponse,
    GetSettingsResponse,
    GetUserResponse,
    PostCoinsResponse,
    PostLandscapesProgressResponse,
    PostLanguageSettingsResponse,
    PostUserResponse,
    PostWordsProgressResponse,
    PutSettingsResponse
} from './types';

const apiUrl = path => {
    return API_URL + path;
};

const RESPONSE_DELAY = 1000;

type GetUserParams = null;
type GetUserBody = null;

const getUser = http.get<GetUserParams, GetUserBody, GetUserResponse>(
    apiUrl('/user'),
    async ({ request }) => {
        await delay(RESPONSE_DELAY);
        // return new HttpResponse(null, { status: 500 });

        const token = request.headers.get('Authorization');
        console.log('token:', token.substring(0, 25), '...');

        if (!token || !checkIfUserExists(token)) {
            return HttpResponse.json(undefined, { status: 204 });
        }

        const user = loadUser();
        return HttpResponse.json(user);
    }
);

type PostUserParams = null;
type PostUserBody = null;

const postUser = http.post<PostUserParams, PostUserBody, PostUserResponse>(
    apiUrl('/user'),
    async ({ request }) => {
        await delay(RESPONSE_DELAY);
        // return new HttpResponse(null, { status: 500 });

        const token = request.headers.get('Authorization');

        if (token) {
            addUser(token);
        }

        const user = loadUser();
        return HttpResponse.json(user);
    }
);

const PRICE_PER_WORD_IN_CATEGORY = 50;
const XP_PER_COIN_REWARD = 3;
const XP_PER_COIN_REWARD_FOR_REVIEW_WITHOUT_SPACED_REP = 1;
const AUDIO_HINT_PRICE = 25;
const MAGIC_WAND_HINT_PRICE = 50;

type GetConfigParams = null;
type GetConfigBody = null;

const getGameConfig = http.get<
    GetConfigParams,
    GetConfigBody,
    GetGameConfigResponse
>(apiUrl('/game-config'), async () => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    return HttpResponse.json({
        pricePerWordInCategory: PRICE_PER_WORD_IN_CATEGORY,
        audioHintPrice: AUDIO_HINT_PRICE,
        magicWandHintPrice: MAGIC_WAND_HINT_PRICE,
        xpPerCoinReward: XP_PER_COIN_REWARD,
        xpPerCoinRewardForReviewWithoutSpacedRep:
            XP_PER_COIN_REWARD_FOR_REVIEW_WITHOUT_SPACED_REP,
        shopItems: [
            {
                code: '100-lingocoins',
                coins: 100,
                priceInStars: { amount: 1, label: '100 LingoCoins' }
            },
            {
                code: '200-lingocoins',
                coins: 200,
                priceInStars: { amount: 2, label: '200 LingoCoins' }
            },
            {
                code: '300-lingocoins',
                coins: 300,
                priceInStars: { amount: 3, label: '300 LingoCoins' }
            }
        ],
        streakRewards: [
            {
                minStreak: 1,
                reward: 10,
                type: 'xp'
            },
            {
                minStreak: 2,
                reward: 25,
                type: 'xp'
            },
            {
                minStreak: 3,
                reward: 50,
                type: 'xp'
            },
            {
                minStreak: 4,
                reward: 75,
                type: 'xp'
            },
            {
                minStreak: 5,
                reward: 100,
                type: 'xp'
            },
            {
                minStreak: 10,
                reward: 125,
                type: 'xp'
            },
            {
                minStreak: 15,
                reward: 150,
                type: 'xp'
            },
            {
                minStreak: 20,
                reward: 200,
                type: 'xp'
            },
            {
                minStreak: 25,
                reward: 250,
                type: 'xp'
            },
            {
                minStreak: 30,
                reward: 300,
                type: 'xp'
            },
            {
                minStreak: 35,
                reward: 350,
                type: 'xp'
            },
            {
                minStreak: 40,
                reward: 400,
                type: 'xp'
            },
            {
                minStreak: 45,
                reward: 450,
                type: 'xp'
            },
            {
                minStreak: 50,
                reward: 2, // means rarity: rare
                type: 'stamp'
            },
            {
                minStreak: 51,
                reward: 500,
                type: 'xp'
            },
            {
                minStreak: 55,
                reward: 550,
                type: 'xp'
            },
            {
                minStreak: 60,
                reward: 600,
                type: 'xp'
            },
            {
                minStreak: 65,
                reward: 650,
                type: 'xp'
            },
            {
                minStreak: 70,
                reward: 700,
                type: 'xp'
            },
            {
                minStreak: 75,
                reward: 750,
                type: 'xp'
            },
            {
                minStreak: 100,
                reward: 3, // means rarity: epic
                type: 'stamp'
            },
            {
                minStreak: 76,
                reward: 750,
                type: 'xp'
            },
            {
                minStreak: 150,
                reward: 3, // means rarity: epic
                type: 'stamp'
            },
            {
                minStreak: 151,
                reward: 750,
                type: 'xp'
            }
        ]
    });
});

type GetSettingsParams = null;
type GetSettingsBody = null;

const getSettings = http.get<
    GetSettingsParams,
    GetSettingsBody,
    GetSettingsResponse
>(apiUrl('/user/settings'), async () => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const userSettings = loadSettings();
    return HttpResponse.json(userSettings);
});

type PutSettingsParams = null;
type PutSettingsBody = Partial<UserSettings>;

const putSettings = http.put<
    PutSettingsParams,
    PutSettingsBody,
    PutSettingsResponse
>(apiUrl('/user/settings'), async ({ request }) => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const updatedSettings = await request.json();

    const currentSettings = loadSettings();
    const newSettings = { ...currentSettings, ...updatedSettings };
    saveUserSettings(newSettings);

    return HttpResponse.json(newSettings);
});

type PostLanguageSettingsParams = {
    lang: string;
};
type PostLanguageSettingsBody = LanguageSettings;

const postLanguageSettings = http.post<
    PostLanguageSettingsParams,
    PostLanguageSettingsBody,
    PostLanguageSettingsResponse
>(apiUrl('/user/:lang/settings'), async ({ params, request }) => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const { lang } = params;
    const { languageLevel } = await request.json();
    saveLanguageLevel(lang, languageLevel);

    const updatedSettings = loadSettings();
    return HttpResponse.json(updatedSettings);
});

type PostWordsProgressParams = {
    lang: string;
};
type PostWordsProgressBody = {
    categoryProgressItems: CategoryProgressItem[];
};

const postWordsProgress = http.post<
    PostWordsProgressParams,
    PostWordsProgressBody,
    PostWordsProgressResponse
>(apiUrl('/user/:lang/progress/words'), async ({ params, request }) => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const { lang } = params;
    const { categoryProgressItems } = await request.json();

    saveWordsProgress(lang, categoryProgressItems);

    return HttpResponse.json({
        categoryProgressItems
    });
});

type PostLandscapesProgressParams = {
    lang: string;
};
type PostLandscapesProgressBody = LandscapeProgressItem;

const postLandscapesProgress = http.post<
    PostLandscapesProgressParams,
    PostLandscapesProgressBody,
    PostLandscapesProgressResponse
>(apiUrl('/user/:lang/progress/landscapes'), async ({ params, request }) => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const { lang } = params;
    const landscapeProgressItem = await request.json();
    saveLandscapesProgress(lang, landscapeProgressItem);
    return HttpResponse.json(landscapeProgressItem);
});

type PostCoinsParams = null;
type PostCoinsBody = {
    coins: number;
};

const postCoins = http.post<PostCoinsParams, PostCoinsBody, PostCoinsResponse>(
    apiUrl('/user/coins'),
    async ({ request }) => {
        await delay(RESPONSE_DELAY);
        // return new HttpResponse(null, { status: 500 });

        const { coins } = await request.json();
        saveCoins(coins);
        return HttpResponse.json({ coins });
    }
);

type PutUserProgressParams = {
    lang: string;
};
type PutUserProgressBody = Partial<{
    xp: number;
    gameLevel: number;
    selectedLandscapeId: string;
    landscapeProgress: number;
}>;
type PutUserProgressResponse = GameProgressValues;

const putLanguageProgress = http.put<
    PutUserProgressParams,
    PutUserProgressBody,
    PutUserProgressResponse
>(apiUrl('/user/:lang/progress'), async ({ params, request }) => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const { lang } = params;
    const updates = await request.json();

    // Update fields based on the request body
    if (updates.xp !== undefined) {
        saveXp(lang, updates.xp);
    }

    if (updates.gameLevel !== undefined) {
        saveGameLevel(lang, updates.gameLevel);
    }

    if (updates.selectedLandscapeId !== undefined) {
        saveLandscapeId(lang, updates.selectedLandscapeId);
    }

    if (updates.landscapeProgress !== undefined) {
        saveLandscapeProgress(lang, updates.landscapeProgress);
    }

    const updatedProgress = getLanguageProgress(lang);

    return HttpResponse.json(updatedProgress, { status: 200 });
});

type PostClearUserDataParams = null;
type PostClearUserDataBody = null;
type PostClearUserDataResponse = null;

// POST /clear-user-data
const postClearUserData = http.post<
    PostClearUserDataParams,
    PostClearUserDataBody,
    PostClearUserDataResponse
>(apiUrl('/user/clear-storage'), async () => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    clearStorage();
    return HttpResponse.json(null, { status: 200 });
});

type GetLeaderboardParams = {
    lang: string;
};
type GetLeaderboardBody = null;

// GET /user/:lang/api/leaderboard?top=10&window=5
const getLeaderboard = http.get<
    GetLeaderboardParams,
    GetLeaderboardBody,
    GetLeaderboardResponse
>(apiUrl('/user/:lang/leaderboard'), async ({ params, request }) => {
    await delay(RESPONSE_DELAY);
    // return new HttpResponse(null, { status: 500 });

    const { lang } = params;
    const url = new URL(request.url);
    const top = url.searchParams.get('top')
        ? parseInt(url.searchParams.get('top'))
        : 10;
    const window = url.searchParams.get('window')
        ? parseInt(url.searchParams.get('window'))
        : 5;

    console.log('lang:', lang, 'top:', top, 'window:', window);

    const responseData: LeaderboardData = {
        topPlayers: [
            {
                rank: 1,
                username: 'EliteGamer',
                xp: 150000
            },
            {
                rank: 2,
                username: 'ProPlayer',
                xp: 145000
            },
            {
                rank: 3,
                username: 'MasterMind',
                xp: 140000
            }
        ],
        currentPlayer: {
            rank: 10,
            username: 'CurrentUser',
            xp: 80000
        },
        surroundingPlayers: {
            before: [
                {
                    rank: 7,
                    username: 'Bolt',
                    xp: 120000
                },
                {
                    rank: 8,
                    username: 'Turbo',
                    xp: 115000
                },
                {
                    rank: 9,
                    username: 'Zoom',
                    xp: 110000
                }
            ],
            after: [
                {
                    rank: 11,
                    username: 'Rocket',
                    xp: 75000
                },
                {
                    rank: 12,
                    username: 'Blaze',
                    xp: 70000
                },
                {
                    rank: 13,
                    username: 'Comet',
                    xp: 65000
                }
            ]
        }
    };

    // Return the hardcoded data with a 200 OK status
    return HttpResponse.json(responseData, {
        status: 200
    });
});

type GetProgressAll = {
    lang: string;
};
type GetProgressAllBody = null;

const getProgressAll = http.get<
    GetProgressAll,
    GetProgressAllBody,
    GetAllProgressResponse
>(apiUrl('/user/:lang/progress/all'), async ({ params, request }) => {
    await delay(RESPONSE_DELAY);

    const { lang } = params;
    const token = request.headers.get('Authorization');

    if (!token || !checkIfUserExists(token)) {
        return HttpResponse.json(undefined, { status: 204 });
    }

    const languageProgress = loadProgress(lang);
    const categoriesProgress = loadWordsProgress(lang);
    const landscapesProgress = loadLandscapesProgress(lang);

    const allProgress = {
        languageProgress,
        categoriesProgress,
        landscapesProgress
    };

    return HttpResponse.json(allProgress);
});

export const handlers = [
    postClearUserData, // POST /user/clear-storage
    getGameConfig, // GET /game-config
    getSettings, // GET /user/settings
    getLeaderboard, // GET /user/:lang/leaderboard
    getUser, // GET /user
    postCoins, // POST /user/coins
    putLanguageProgress, // PUT /user/:lang/progress
    postLandscapesProgress, // POST /user/:lang/progress/landscapes
    postLanguageSettings, // POST /user/:lang/settings
    postUser, // POST /user
    postWordsProgress, // POST /user/:lang/progress/words
    putSettings, // PUT /user/settings,
    getProgressAll // GET /user/:lang/progress/all
];
