import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import { Dialogue, HeaderWithCloseAndBackButton } from '@/components';
import { useLabels, useNavigation, useSettings } from '@/context';
import { getAvailableUILanguagesForStudyLanguage } from '@/utils/languages';

import ProfileLanguageButton from './components/ProfileLanguageButton';

const ChangeUILanguageScreen = () => {
    const { getLabel } = useLabels();
    const { closeProfile, navigate, navigateToProfile } = useNavigation();
    const { settings, setUILanguage } = useSettings();

    const [isDialogueVisible, setIsDialogueVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    const [changeUILanguageState, doChangeUILanguage] = useAsyncFn(async () => {
        try {
            await setUILanguage(selectedLanguage);
            navigate('Splash');
        } catch (error) {
            console.error(
                'Error changing UI language:',
                changeUILanguageState.error
            );
            toast.error(getLabel('change-language.language-change-failed'));
        }
    }, [setUILanguage, selectedLanguage, navigate, getLabel]);

    const showLanguageButtons = () => {
        const uiLanguages = getAvailableUILanguagesForStudyLanguage(
            settings.studyLanguage
        );

        const availableLanguages = uiLanguages
            .filter(l => l.languageCode !== settings.uiLanguage)
            .map(l => l.languageCode);

        return availableLanguages.map((languageCode, index) => {
            return (
                <ProfileLanguageButton
                    key={index}
                    onButtonPress={() => {
                        setSelectedLanguage(languageCode);
                        setIsDialogueVisible(true);
                    }}
                    label={getLabel(`language.${languageCode}`)}
                    languageCode={languageCode}
                    disabled={false}
                />
            );
        });
    };

    const onDialogueClose = () => {
        setIsDialogueVisible(false);
    };

    const onChangeUILanguage = async () => {
        if (!selectedLanguage) return;

        await doChangeUILanguage();
    };

    const otherAvailableUiLanguages = getAvailableUILanguagesForStudyLanguage(
        settings.studyLanguage
    ).filter(lang => lang.languageCode !== settings.uiLanguage);

    return (
        <>
            <div>
                <HeaderWithCloseAndBackButton
                    backTitle=""
                    onBack={() => navigateToProfile('LanguageSettings')}
                    onClose={() => closeProfile()}
                />
                <h2>{getLabel('change-language.language-you-speak')}</h2>
                <div className="mb-5 mt-3 text-lg text-white">
                    {otherAvailableUiLanguages.length > 0
                        ? getLabel('change-language.do-you-want-to-change')
                        : getLabel('change-language.no-other-ui-languages')}
                </div>
                {showLanguageButtons()}
            </div>
            <Dialogue
                isVisible={isDialogueVisible}
                onButton1Press={onChangeUILanguage}
                onButton2Press={onDialogueClose}
                onClose={onDialogueClose}
                title={getLabel('change-language.change-language')}
                text={getLabel('change-language.change-ui-language')}
                button1Text={getLabel('button.yes')}
                button2Text={getLabel('button.no')}
                outline
                button1Loading={changeUILanguageState.loading}
            />
        </>
    );
};

export default ChangeUILanguageScreen;
