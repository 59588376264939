import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import { WordInfoLocalized } from '@/types/category';

import { useGameProgress } from './GameProgressContext';
import { useSettings } from './SettingsContext';

interface ReviewGameSessionContextType {
    reviewGameSession: ReviewGameSession;
    completeReviewGameSession: (
        correctWords: WordInfoLocalized[],
        allWords: WordInfoLocalized[]
    ) => Promise<void>;
    updateReviewGameSessionSteps: (
        currentStep: number,
        totalSteps: number
    ) => void;
    resetReviewGameSession: () => void;
    addFoundWord: (word: WordInfoLocalized) => void;
    addCorrectWord: (word: WordInfoLocalized) => void;
}

interface ReviewGameSession {
    correctWords: WordInfoLocalized[];
    foundWords: WordInfoLocalized[];
    currentStep: number;
    totalSteps: number;
}

const NEW_REVIEW_GAME_SESSION: ReviewGameSession = {
    correctWords: [],
    foundWords: [],
    currentStep: 1,
    totalSteps: 3
};

export const ReviewGameSessionContext = createContext<
    ReviewGameSessionContextType | undefined
>(undefined);

interface ReviewGameSessionProviderProps {
    children: ReactNode;
}

export const ReviewGameSessionProvider: FC<ReviewGameSessionProviderProps> = ({
    children
}) => {
    const { settings } = useSettings();
    const { updateReviewProgress, resetCategories } = useGameProgress();

    const [reviewGameSession, setReviewSessionProgress] =
        useState<ReviewGameSession>(NEW_REVIEW_GAME_SESSION);

    const updateReviewGameSessionSteps = useCallback(
        (currentStep: number, totalSteps: number) => {
            setReviewSessionProgress(prev => ({
                ...prev,
                currentStep,
                totalSteps
            }));
        },
        []
    );

    const resetReviewGameSession = useCallback(() => {
        setReviewSessionProgress(NEW_REVIEW_GAME_SESSION);
    }, []);

    const addFoundWord = useCallback((word: WordInfoLocalized) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            foundWords: [...prev.foundWords, word]
        }));
    }, []);

    const addCorrectWord = useCallback((word: WordInfoLocalized) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            correctWords: [...prev.correctWords, word]
        }));
    }, []);

    /**
     * Completes the review game session by updating the state, sending progress to the API,
     * and resetting categories.
     */
    const completeReviewGameSession = useCallback(
        async (
            correctWords: WordInfoLocalized[],
            allWords: WordInfoLocalized[]
        ) => {
            // Update the review session state
            setReviewSessionProgress(prev => ({
                ...prev,
                correctWords,
                foundWords: allWords
            }));

            // Update the review status in the backend
            await updateReviewProgress(settings.studyLanguage, correctWords);
            resetCategories();
        },
        [settings, updateReviewProgress, resetCategories]
    );

    /**
     * Memoizes the context value to prevent unnecessary re-renders of consuming components.
     */
    const contextValue = useMemo(
        () => ({
            reviewGameSession,
            completeReviewGameSession,
            updateReviewGameSessionSteps,
            resetReviewGameSession,
            addFoundWord,
            addCorrectWord
        }),
        [
            reviewGameSession,
            completeReviewGameSession,
            updateReviewGameSessionSteps,
            resetReviewGameSession,
            addFoundWord,
            addCorrectWord
        ]
    );

    return (
        <ReviewGameSessionContext.Provider value={contextValue}>
            {children}
        </ReviewGameSessionContext.Provider>
    );
};

export const useReviewGameSession = () => {
    const context = useContext(ReviewGameSessionContext);
    if (!context) {
        throw new Error(
            'useReviewGameSession must be used within a ReviewGameSessionProvider'
        );
    }
    return context;
};
