import { useState } from 'react';

import { SoundName } from '@/audio/AudioManager';
import HeaderWithCloseAndBackButton from '@/components/HeaderWithCloseAndBackButton';
import ForwardIcon from '@/components/icons/ForwardIcon';
import { useGameProgress, useLabels, useNavigation } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { COLORS } from '@/utils/colors';

import settingsIcon from '../../../assets/images/icons/settings.png';

import Settings from './Settings';

const AccountScreen = () => {
    const { getWordsLearnedCount } = useGameProgress();
    const { closeProfile, navigateToProfile } = useNavigation();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const [isShowSettings, setIsShowSettings] = useState(false);

    const onWordsLearnedPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        navigateToProfile('WordsLearned');
    };

    const onCategoriesPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        navigateToProfile('Categories');
    };

    const onLandscapesPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        navigateToProfile('Landscapes');
    };

    const onSettingsPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        setIsShowSettings(true);
    };

    return (
        <>
            <div className="flex h-full flex-col">
                <HeaderWithCloseAndBackButton onClose={closeProfile} />
                <h2 className="text-2xl font-bold">
                    {getLabel('profile.statistics')}
                </h2>
                <div className="mb-7 mt-2.5 flex justify-between">
                    <button
                        className={`flex w-1/2 flex-col items-start rounded-3xl border-2 border-white border-opacity-30 p-2.5`}
                        onClick={onWordsLearnedPress}
                        disabled={getWordsLearnedCount() < 1}
                    >
                        <h2 className="text-2xl font-bold">
                            {getWordsLearnedCount()}
                        </h2>
                        <p className="text-sm text-gray-200">
                            {getLabel('profile.words-learned')}
                        </p>
                    </button>
                </div>
                <button
                    className="mb-5 flex items-center justify-between"
                    onClick={onCategoriesPress}
                >
                    <h2 className="text-2xl font-bold">
                        {getLabel('profile.vocabulary-topics')}
                    </h2>
                    <ForwardIcon width={15} height={15} color={COLORS.white} />
                </button>
                <button
                    className="mb-8 flex items-center justify-between"
                    onClick={onLandscapesPress}
                >
                    <h2 className="text-2xl font-bold">
                        {getLabel('landscapes')}
                    </h2>
                    <ForwardIcon width={15} height={15} color={COLORS.white} />
                </button>
                <button
                    onClick={onSettingsPress}
                    className="flex items-center justify-center rounded-full bg-black bg-opacity-30 p-1.5 text-xl text-white200"
                >
                    <img
                        src={settingsIcon}
                        alt="Settings"
                        className="mr-2 h-6 w-6"
                    />
                    {getLabel('settings.settings')}
                </button>
            </div>
            <Settings
                isVisible={isShowSettings}
                onClose={() => setIsShowSettings(false)}
            />
        </>
    );
};

export default AccountScreen;
