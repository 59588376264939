import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import { SoundName } from '@/audio/AudioManager';
import TextButton from '@/components/buttons/TextButton';
import HeaderWithCloseAndBackButton from '@/components/HeaderWithCloseAndBackButton';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useProfile,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';

const LandscapeInfoScreen = () => {
    const { vibrateLight } = useVibration();
    const { getLabel } = useLabels();
    const { settings } = useSettings();
    const { navigateToProfile, closeProfile } = useNavigation();
    const { currentLandscape } = useProfile();
    const { updateSelectedLandscapeId } = useGameProgress();

    const [handleUseLandscapeState, doHandleUseLandscape] =
        useAsyncFn(async () => {
            try {
                vibrateLight();
                await updateSelectedLandscapeId(
                    settings.studyLanguage,
                    currentLandscape.id
                );
                emitter.emit('playSound', { sound: SoundName.Reveal });
                closeProfile();
            } catch (error) {
                console.error(error);
                toast.error(getLabel('error.failed-to-update-landscape'));
                throw error;
            }
        }, [currentLandscape, settings.studyLanguage]);

    const onUseLandscapePress = () => {
        doHandleUseLandscape();
    };

    if (!currentLandscape) return null;

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Landscapes')}
                backTitle={getLabel('common.back')}
            />
            <div className="mt-4 flex flex-grow flex-col items-center overflow-y-scroll">
                <h2 className="mb-4 text-center text-xl font-bold">
                    {currentLandscape.name}
                </h2>
                <img
                    src={currentLandscape.imageUrl}
                    alt={currentLandscape.name}
                    className="mb-6 h-[60vh] object-contain"
                />
                <TextButton
                    className="w-full"
                    text={getLabel('gift-info.use-this-landscape')}
                    onClick={onUseLandscapePress}
                    isDisabled={handleUseLandscapeState.loading}
                    isLoading={handleUseLandscapeState.loading}
                />
            </div>
        </div>
    );
};

export default LandscapeInfoScreen;
