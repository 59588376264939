import { type FC, useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import {
    Dialogue,
    Modal,
    Panel,
    TextButton,
    TooltipContent
} from '@/components';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useNewWordsGameSession,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import Shop from '@/shop/Shop';
import { commonStyles } from '@/styles/commonStyles';
import { CategoryInfoWithProgress } from '@/types/category';

import friends from '../../../assets/images/icons/friends.png';
import shop from '../../../assets/images/icons/shop.png';
import stamps from '../../../assets/images/icons/stamps.png';
import streak from '../../../assets/images/icons/streak.png';
import tasks from '../../../assets/images/icons/tasks.png';

import { CategoryCard } from './components/CategoryCard';

import 'tippy.js/animations/scale-subtle.css';

const MENU_ICONS = {
    shop: shop,
    friends: friends,
    streak: streak,
    tasks: tasks,
    stamps: stamps
};

const calculateCircleSize = (wordsToReview: number) => {
    const baseSize = 30;
    const increment = 10; // Increment size for each additional character
    const length = wordsToReview.toString().length;
    return baseSize + increment * (length - 1);
};

const ChooseCategoryScreen: FC = () => {
    const { navigate } = useNavigation();
    const [isShopVisible, setIsShopVisible] = useState(false);
    const [isReviewDialogueVisible, setIsReviewDialogueVisible] =
        useState(false);
    const [levelText, setLevelText] = useState('');
    const [hintText, setHintText] = useState('');
    const {
        loadCategoriesToChooseFrom,
        chooseCategory,
        categoriesToChooseFrom,
        gameProgress,
        getWordsToReviewCount,
        updateIsNoWordsToReviewWithSpacedRep
    } = useGameProgress();
    const {
        settings,
        setIsReviewWithoutSpacedRepViewed,
        updateIsReviewModeTooltipNeeded
    } = useSettings();
    const { resetGameSession } = useNewWordsGameSession();
    const { resetReviewGameSession } = useReviewGameSession();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const [wordsToReviewCount, setWordsToReviewCount] = useState(() =>
        getWordsToReviewCount()
    );

    const [circleSize, setCircleSize] = useState(
        calculateCircleSize(wordsToReviewCount)
    );

    const [isReviewTooltipVisible, setIsReviewTooltipVisible] = useState(
        gameProgress.gameLevel === 3 && settings.isReviewModeTooltipNeeded
    );

    const [isComingSoonModalVisible, setIsComingSoonModalVisible] =
        useState(false);

    useEffect(() => {
        const handleAppStateChange = (nextAppState: string) => {
            if (nextAppState === 'active') {
                updateWordsToReviewCount();
            }
        };

        window.addEventListener('focus', () => handleAppStateChange('active'));

        return () => {
            window.removeEventListener('focus', () =>
                handleAppStateChange('active')
            );
        };
    }, []);

    const updateWordsToReviewCount = () => {
        const count = getWordsToReviewCount();
        setWordsToReviewCount(count);
        setCircleSize(calculateCircleSize(count));
    };

    useEffect(() => {
        if (wordsToReviewCount === 0) return;

        // Animation logic handled by Framer Motion in JSX
    }, [wordsToReviewCount]);

    useEffect(() => {
        resetGameSession();
        resetReviewGameSession();

        if (!categoriesToChooseFrom) {
            loadCategoriesToChooseFrom(settings.languageLevel);
        }
    }, []);

    useEffect(() => {
        if (!categoriesToChooseFrom) return;

        const impressiveLabel = getLabel('choose-category.impressive');
        const noTopicsLabel = getLabel('choose-category.no-topics');
        const oneTopicLabel = getLabel('choose-category.one-topic');
        const pickFirstTopicLabel = getLabel(
            'choose-category.pick-first-topic'
        );
        const pickNextTopicLabel = getLabel('choose-category.pick-next-topic');

        if (categoriesToChooseFrom.length === 0) {
            setLevelText(impressiveLabel);
            setHintText(noTopicsLabel);
        } else if (categoriesToChooseFrom.length === 1) {
            setHintText(oneTopicLabel);
        } else {
            setHintText(
                gameProgress.gameLevel === 1
                    ? pickFirstTopicLabel
                    : pickNextTopicLabel
            );
        }
    }, [categoriesToChooseFrom]);

    const onPickTopicPress = () => {
        navigate('ChooseAnotherCategory');
    };

    const onReviewPress = () => {
        vibrateLight();
        emitter.emit('playSound', { sound: SoundName.Click2 });
        if (wordsToReviewCount === 0) {
            updateIsNoWordsToReviewWithSpacedRep(true);
            if (settings.isReviewWithoutSpacedRepViewed) {
                navigate('ReviewPuzzle');
            } else {
                setIsReviewDialogueVisible(true);
                return;
            }
        } else {
            updateIsNoWordsToReviewWithSpacedRep(false);
            navigate('ReviewPuzzle');
        }
    };

    const onContinueReviewPress = () => {
        setIsReviewWithoutSpacedRepViewed();
        setIsReviewDialogueVisible(false);
        navigate('ReviewPuzzle');
    };

    const onReviewDialogueClose = () => {
        setIsReviewDialogueVisible(false);
    };

    const onChooseCategory = (category: CategoryInfoWithProgress) => {
        chooseCategory(category);
        if (settings.isSkipWordsIntro) {
            navigate('Puzzle');
        } else {
            navigate('WordsIntro');
        }
    };

    const onShopPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        setIsShopVisible(true);
    };

    const onMenuButtonPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        setIsComingSoonModalVisible(true);
    };

    if (!categoriesToChooseFrom) return null;

    return (
        <>
            {isReviewTooltipVisible && (
                <div className="fixed inset-0 z-20 bg-black opacity-70"></div>
            )}
            <div className="relative flex h-full flex-col justify-center pb-14 pt-2">
                <Panel className={commonStyles.panelWrapper}>
                    {levelText && <h1>{levelText}</h1>}
                    <p className="mb-5 font-nunito text-2xl font-bold text-white">
                        {hintText} {settings.uiLanguage !== 'ru' && '✨'}
                    </p>
                    {categoriesToChooseFrom.length > 0 && (
                        <div className="mb-5 flex flex-wrap items-start justify-around">
                            {categoriesToChooseFrom.map(category => (
                                <CategoryCard
                                    key={category.categoryInfo.id}
                                    category={category}
                                    onChooseCategory={onChooseCategory}
                                />
                            ))}
                        </div>
                    )}
                    {categoriesToChooseFrom.length > 1 && (
                        <TextButton
                            className="w-full py-2"
                            bgColor="bg-grey600"
                            textStyle="text-white"
                            textSizeStyle="text-base"
                            text={getLabel('choose-category.see-other-topics')}
                            onClick={onPickTopicPress}
                        />
                    )}
                </Panel>
                {gameProgress.gameLevel > 2 && (
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel(
                                    'choose-category.review-learned-words'
                                )}
                                text={getLabel(
                                    'choose-category.review-tooltip-text'
                                )}
                                className={`w-[calc(100vw-16px)]`}
                            />
                        }
                        visible={
                            isReviewTooltipVisible &&
                            settings.isReviewModeTooltipNeeded
                        }
                        placement="top-start"
                        onClickOutside={() => {
                            setIsReviewTooltipVisible(false);
                            void updateIsReviewModeTooltipNeeded(false);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div
                            className={classNames(
                                'relative flex w-full',
                                isReviewTooltipVisible && 'z-30'
                            )}
                        >
                            <motion.button
                                whileTap={{ scale: 0.95 }}
                                initial={{ scale: 1 }}
                                animate={{ scale: 1 }}
                                className="flex w-full items-center justify-center rounded-2xl bg-grey700 bg-opacity-80 p-3 px-4 shadow-extraDark"
                                onClick={onReviewPress}
                            >
                                <span className="font-nunito text-base font-extraBold text-white">
                                    {getLabel('choose-category.review', true)}
                                </span>
                                {wordsToReviewCount > 0 && (
                                    <motion.div
                                        className="absolute right-0 top-[-10px] flex items-center justify-center rounded-full bg-orange"
                                        style={{
                                            width: `${circleSize}px`,
                                            height: `${circleSize}px`
                                        }}
                                        initial={{ scale: 1 }}
                                        animate={{
                                            opacity: 1,
                                            scale: [1, 1.2, 1],
                                            transition: {
                                                duration: 2.5,
                                                repeat: Infinity,
                                                repeatType: 'loop'
                                            }
                                        }}
                                    >
                                        <span className="font-bold text-white">
                                            {wordsToReviewCount}
                                        </span>
                                    </motion.div>
                                )}
                            </motion.button>
                        </div>
                    </Tippy>
                )}
                <BottomMenu
                    onShopPress={onShopPress}
                    onMenuButtonPress={onMenuButtonPress}
                />
                <Dialogue
                    text={getLabel('choose-category.review-without-bonus')}
                    onButton1Press={onContinueReviewPress}
                    onButton2Press={onReviewDialogueClose}
                    onClose={onReviewDialogueClose}
                    isVisible={isReviewDialogueVisible}
                    button1Text={getLabel('button.yes')}
                    button2Text={getLabel('button.no')}
                />
                <Shop
                    isVisible={isShopVisible}
                    onClose={() => setIsShopVisible(false)}
                />
                <Modal
                    isVisible={isComingSoonModalVisible}
                    onClose={() => setIsComingSoonModalVisible(false)}
                >
                    <div className="text-white">
                        {getLabel('common.coming-soon')}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ChooseCategoryScreen;

const BottomMenu = ({
    onShopPress,
    onMenuButtonPress
}: {
    onShopPress: () => void;
    onMenuButtonPress: () => void;
}) => {
    const { getLabel } = useLabels();

    const buttons = [
        { label: getLabel('menu.friends'), value: 'friends' },
        { label: getLabel('menu.streak'), value: 'streak' },
        { label: getLabel('menu.tasks'), value: 'tasks' },
        { label: getLabel('menu.stamps'), value: 'stamps' },
        { label: getLabel('shop'), value: 'shop' }
    ];

    return (
        <div className="fixed bottom-0 left-0 flex h-20 w-full rounded-t-3xl border-t border-white/40 bg-grey/90 pb-4">
            {buttons.map(button => (
                <MenuButton
                    button={button}
                    key={button.value}
                    onClick={
                        button.value === 'shop'
                            ? onShopPress
                            : onMenuButtonPress
                    }
                />
            ))}
        </div>
    );
};

const MenuButton = ({
    button,
    onClick
}: {
    button: { label: string; value: string };
    onClick: () => void;
}) => {
    const disabled = button.value !== 'shop';
    return (
        <div
            className="flex w-1/5 flex-col items-center justify-center"
            onClick={onClick}
        >
            <img
                src={MENU_ICONS[button.value]}
                className={classNames('mb-1.5 h-5', disabled && 'opacity-50')}
            ></img>
            <p
                className={classNames(
                    'font-nunito text-xs tracking-wider',
                    disabled ? 'text-grey400' : 'text-white'
                )}
            >
                {button.label}
            </p>
        </div>
    );
};
