import classNames from 'classnames';

import { HeaderWithCloseAndBackButton } from '@/components';
import { useGameProgress, useLabels, useNavigation } from '@/context';
import useDisableVerticalSwipe from '@/hooks/useDisableVertialSwipe';
import { WordInfoLocalized } from '@/types/category';

import WordItem from './components/WordItem';

const WordsLearnedScreen = () => {
    useDisableVerticalSwipe();
    const { getLabel } = useLabels();
    const { navigateToProfile, closeProfile } = useNavigation();

    const { gameProgress } = useGameProgress();

    const categoriesWithProgress = gameProgress.categoriesProgress.filter(
        category => category.categoryProgressItem.wordProgressItems.length > 0
    );

    const categoriesWithProgressContent = categoriesWithProgress
        .map(cat => cat.categoryInfo.content)
        .flat();

    const learnedWordsIds = gameProgress.categoriesProgress
        .map(progress => progress.categoryProgressItem.wordProgressItems)
        .flat()
        .map(word => word.wordId);

    const learnedWords = learnedWordsIds.map(id =>
        categoriesWithProgressContent.find(word => word.id === id)
    );

    const learnedWordsByCategory = learnedWords.reduce(
        (acc, currentWord) => {
            const { categoryId } = currentWord;
            if (!acc[categoryId]) {
                acc[categoryId] = [];
            }
            acc[categoryId].push(currentWord);
            return acc;
        },
        {} as Record<string, WordInfoLocalized[]>
    );

    const learnedWordsByCategoryArray = Object.keys(learnedWordsByCategory).map(
        categoryId => ({
            categoryId,
            words: learnedWordsByCategory[categoryId]
        })
    );

    const getCategoryName = (id: string) => {
        return gameProgress.categoriesProgress.find(
            category => category.categoryInfo.id === id
        ).categoryInfo.name;
    };

    const isLastCategory = (index: number) => {
        return index !== learnedWordsByCategoryArray.length - 1;
    };

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Account')}
                backTitle={getLabel('common.back')}
            />
            <h2 className="py-2">{getLabel('profile.words-learned')}</h2>
            <div className="flex-grow overflow-y-scroll">
                <div className="py-5">
                    {learnedWordsByCategoryArray.map((category, index) => (
                        <div
                            key={category.categoryId}
                            className={classNames(
                                'py-5',
                                isLastCategory(index) &&
                                    'border-b border-white/20'
                            )}
                        >
                            <h3 className="uppercase">
                                {getCategoryName(category.categoryId)}
                            </h3>
                            {category.words.map(word => (
                                <WordItem
                                    item={word}
                                    learnt
                                    key={word.id}
                                    hideDot
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WordsLearnedScreen;
