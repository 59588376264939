import { type FC } from 'react';
import classNames from 'classnames';

interface LoadingCircleProps {
    colorClassName?: string;
}

const LoadingCircle: FC<LoadingCircleProps> = ({ colorClassName }) => {
    return (
        <div className="flex w-full justify-center py-0.5">
            <div className="relative h-6 w-6">
                <div
                    className={classNames(
                        'absolute inset-0 animate-spin-slow rounded-full border-4 border-transparent border-b-current border-l-current border-r-current',
                        colorClassName
                    )}
                ></div>
            </div>
        </div>
    );
};

export default LoadingCircle;
