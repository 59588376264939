import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.
import './mockEnv.ts';

import { Root } from './Root';

import './index.css';

Sentry.init({
    dsn: 'https://f1a010a8afbd41ab94cb6bfb6cc3a26c@o4507237593841664.ingest.de.sentry.io/4507929690964048',
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] })
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/iljjr8f7wg\.execute-api\.eu-north-1\.amazonaws\.com\/prod\/api/
    ]
});

createRoot(document.getElementById('root')!).render(<Root />);
